<template>
    <div class="home-page profile-main notify">
        <div class="container">
            <header class="mb-md-2 mt-md-2">
                <HeaderNav />
            </header>
        </div>
        <!-- {{ notifyGet }} -->
        <div class="content blue-bg">
            <div class="container">

                <div class="row align-items-top justify-content-center m-0 vh-83">
                    <div class="col-md-7 position-relative d-none d-md-inline">
                        <div class="element-tree w-80">
                            <img src="../assets/images/goldencat-tree.svg" alt="" class="w-100">
                        </div>
                    </div>

                    <div class="col-md-5 dir-rtl">
                        <h1 class="text-white fw-bold nanosans mt-5 mb-5">
                            <button class="btn btn-link btn-arrow pe-0" @click="crossGo"><img
                                    src=".././assets/images/backarrow.svg"></button>
                            الإشعارات
                        </h1>
                        <div class="loadingprofile" v-if="loadingDiv">
                            <div class="wategae" style="height: 100px;"></div>
                            <div class="wategae" style="height: 100px;"></div>
                            <div class="wategae" style="height: 100px;"></div>
                        </div>
                        <div v-else>
                            <div v-if="notifyGet != ''">
                                <div v-for="(value, index) in notifyGet" :key="index">
                                    <div class="col-12 notify-list p-3 p-md-4 mb-4"
                                        :class="value.unread === false ? 'theme-lightgold' : 'theme-gold'">
                                        <div class="row">
                                            <div class="col-md-9 col-8 nanosans">
                                                <h4>{{ value.title }}</h4>
                                                <p>{{ value.message }}</p>
                                                <span class="time-stam">{{ value.created_at_human }}</span>
                                            </div>
                                            <div class="col-4 col-md-3 align-items-center justify-content-end d-flex">
                                                <div class="img-box">
                                                    <img :src="value.image" alt="" style="width: 74%;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-12">
                                    <h4 class="text-white">لا يوجد إشعار الآن</h4>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="row notify-list theme-lightgold p-3 p-md-4 mb-4">
                            <div class="col-md-9 col-8 nanosans">
                                <h4>جمل تحية</h4>
                                <p>اختبار جديد متاح</p>
                                <span class="time-stam">منذ 1 ساعة</span>
                            </div>
                            <div class="col-4 col-md-3 align-items-center justify-content-end d-flex">
                                <div class="img-box">
                                    <img src="../assets/images/wavehand.svg" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="row notify-list theme-gold p-3 p-md-4 mb-4">
                            <div class="col-md-9 col-8 nanosans">
                                <h4>الفاكهة</h4>
                                <p>تمت إضافة درس جديد: طعم الفواكه</p>
                                <span class="time-stam">منذ 1 ساعة</span>
                            </div>
                            <div class="col-4 col-md-3 align-items-center justify-content-end d-flex">
                                <div class="img-box">
                                    <img src="../assets/images/apple.svg" alt="">
                                </div>
                            </div>
                        </div> -->

                        <div class="element-tree w-80 d-block d-md-none mb-5 pb-5 mt-5">
                            <img src="../assets/images/goldencat-tree.svg" alt="" class="w-100">
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>
<script >
import HeaderNav from '.././components/common/HeaderNew.vue'
import axios from 'axios';

export default {
    name: 'HomeMain',
    components: {
        HeaderNav,
    },
    data() {
        return {
            notifyGet: [],
            lastVisit: null, // Store the last time the user visited the notifications page
            loadingDiv: true,

        }
    },
    mounted() {
        this.fetchData();
        this.lastVisit = new Date(); // Set the last visit time when the component is mounted

        // const dataget = JSON.parse(localStorage.getItem('userData'));
        // if (dataget) {
        //     this.profileName = dataget.name
        // }
    },

    methods: {

        async fetchData() {
            try {
                // const token = sessionStorage.getItem('token');
                // console.log(token);
                const response = await axios.get('https://backend.nadyalloughat.com/api/get-notifications', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                    }
                });

                // const currentTime = new Date();
                this.notifyGet = response.data.notifications.map(notification => {
                    // Mark notifications as unread if they are created after the last visit
                    const isUnread = new Date(notification.created_at) > this.lastVisit;

                    return { ...notification, unread: isUnread };
                });
                // this.notifyGet = response.data.notifications;

                // sessionStorage.setItem('wordDay-english', this.profileGet.word_of_the_day.word_english);
                console.log('Notifications:', this.notifyGet);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }

        },

        crossGo() {
            this.lastVisit = new Date();
            window.history.back();
        },
    }
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>