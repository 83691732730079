// Array of country objects for the flag dropdown.

// Here is the criteria for the plugin to support a given country/territory
// - It has an iso2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
// - It has it's own country calling code (it is not a sub-region of another country): https://en.wikipedia.org/wiki/List_of_country_calling_codes
// - It has a flag in the region-flags project: https://github.com/behdad/region-flags/tree/gh-pages/png
// - It is supported by libphonenumber (it must be listed on this page): https://github.com/googlei18n/libphonenumber/blob/master/resources/ShortNumberMetadata.xml

// Each country array has the following information:
// [
//    Country name,
//    iso2 code,
//    International dial code,
//    Order (if >1 country with same dial code),
//    Area codes
// ]
const allCountries = [
  ["Algeria (‫الجزائر‬‎)", "dz", "213"],
  ["Bahrain (‫البحرين‬‎)", "bh", "973"],
  ["Comoros (‫جزر القمر‬‎)", "km", "269"],
  ["Djibouti", "dj", "253"],
  ["Egypt (‫مصر‬‎)", "eg", "20"],
  ["Iran (‫ایران‬‎)", "ir", "98"],
  ["Iraq (‫العراق‬‎)", "iq", "964"],
  // ["Israel (‫ישראל‬‎)", "il", "972"],
  ["Jordan (‫الأردن‬‎)", "jo", "962"],
  ["Kuwait (‫الكويت‬‎)", "kw", "965"],
  ["Lebanon (‫لبنان‬‎)", "lb", "961"],
  ["Libya (‫ليبيا‬‎)", "ly", "218"],
  ["Mauritania (‫موريتانيا‬‎)", "mr", "222"],
  ["Morocco (‫المغرب‬‎)", "ma", "212", 0],
  ["Oman (‫عُمان‬‎)", "om", "968"],
  ["Pakistan (‫پاکستان‬‎)", "pk", "92"],
  ["Palestine (‫فلسطين‬‎)", "ps", "970"],
  ["Qatar (‫قطر‬‎)", "qa", "974"],
  ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "sa", "966"],
  ["South Sudan (‫جنوب السودان‬‎)", "ss", "211"],
  ["Sudan (‫السودان‬‎)", "sd", "249"],
  ["Syria (‫سوريا‬‎)", "sy", "963"],
  ["Tunisia (‫تونس‬‎)", "tn", "216"],
  ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", "ae", "971"],
  ["Western Sahara (‫الصحراء الغربية‬‎)", "eh", "212", 1],
  ["Yemen (‫اليمن‬‎)", "ye", "967"],
  
];

export default allCountries.map(([name, iso2, dialCode]) => ({
  name,
  iso2: iso2.toUpperCase(),
  dialCode
}));
