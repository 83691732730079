<template>
  <div class="home-page grey-bg">
    <div class="container">
      <header class="pb-md-2 pt-md-2">
        <HeaderNav />
      </header>

      <div class="content">
        <div class="row align-items-end justify-content-center mt-md-5">
          <div class="col-md-6 d-none d-md-block">
            <img src="../.././assets/images/cat-straight.svg">
          </div>
          <div class="col-md-6 text-end dir-rtl text-primarydark mt-5 unsubs-detail unsubs-select">

            <div class="alert alert-success" v-if="this.successMessage != ''">{{ successMessage }}</div>
            <div class="alert alert-danger" v-if="this.errorMessage != ''">{{ errorMessage }}</div>

            <h1 class="text-primarydark fw-bold nanosans mb-2">
              <button class="btn btn-link btn-arrow pe-0 d-inline-block" @click="crossGo"><img
                  src="../.././assets/images/arrow-left2.svg"></button>
              حذف الحساب
            </h1>
            <!-- <h3 class="mt-4">Nady Al Loughat Premium</h3> -->
            <h3 class="mt-4">لماذا تريد حذف حسابك؟</h3>
            <!-- {{ unsubs }} -->
            <ul class="mt-4">
              <li>
                <input type="radio" name="unsubs" id="unsubs1" v-model="unsubs" value="انه غالى جدا" checked>
                <label for="unsubs1">انه غالى جدا</label>
              </li>
              <li>
                <input type="radio" name="unsubs" id="unsubs2" v-model="unsubs" value="أنا لا أستخدم هذا بعد الآن">
                <label for="unsubs2">أنا لا أستخدم هذا بعد الآن</label>
              </li>
              <li>
                <input type="radio" name="unsubs" id="unsubs3" v-model="unsubs" value="أنا أتحول إلى منتج آخر">
                <label for="unsubs3">أنا أتحول إلى منتج آخر</label>
              </li>
              <li>
                <input type="radio" name="unsubs" id="unsubs4" v-model="unsubs" value="other">
                <label for="unsubs4">أخرى (إلزامية)</label>
                <div class="mt-3">
                  <textarea name="" id="" rows="4" class="w-100" v-if="unsubs === 'other'" v-model="otherReason"
                    placeholder="اكتب هنا..."></textarea>
                </div>
              </li>
            </ul>
            <div>
              <button @click="popups" class="btn btn-main btn-lg py-3 w-100 nanosans mt-1 text-center">
                حذف الحساب
              </button>
            </div>
          </div>
          <div class="col-md-6 d-block d-md-none text-center mt-5">
            <img src="../.././assets/images/cat-straight.svg">
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="modal fade " :class="modal === true ? 'show' : ''" id="exampleModal1" tabindex="-1" role="dialog"
    aria-labelledby="exampleModal1Label" aria-hidden="true"
    :style="modal === true ? 'display: flex; padding-right: 17px;' : ''">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-body text-center pt-5 pb-5">
          <h3 class="nanosans fw-bold">هل أنت متأكد أنك تريد إلغاء اشتراكك؟</h3>
        </div>
        <div class="modal-footer text-center d-inline-block">
          <button type="button" class="btn btn-secondary" @click="dismiss">لا</button>
          <button type="button" class="btn btn-primary bg-primarydark" @click="unsubscribing" :disabled="loadingDiv">
            <div class="spinner" v-if="loadingDiv"></div>
            نعم
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '../.././components/common/HeaderNew.vue'
import axios from 'axios';

export default {
  name: 'HomeMain',
  components: {
    HeaderNav
  },
  data() {
    return {
      unsubs: '',
      otherReason: '',
      modal: false,
      phone: '',
      subserviceid: '',
      errorMessage: '',
      successMessage: '',
      loadingDiv: false,
    };
  },
  mounted() {
    // this.fetchData();

    const dataget = JSON.parse(localStorage.getItem('userData'));
    if (dataget) {
      this.subserviceid = dataget.subservice_id
      this.phone = dataget.phone
    }

  },

  methods: {
    dismiss() {
      this.modal = false
    },
    popups() {
      if (this.unsubs != 'other') {
        this.otherReason = this.unsubs;
      }
      this.modal = true
    },
    async unsubscribing() {
      this.loadingDiv = true;

      try {
        // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
        const response = await axios.post('https://backend.nadyalloughat.com/api/delete-account', {
          phone: this.phone,
          subService_id: this.subserviceid,
          reason: this.otherReason,
        }, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          mode: 'no-cors',
        });

        if (response.status === 200 || response.status === 201) {
          // this.$router.push({ name: 'Login', query: { redirect: '/login' } });
          this.successMessage = 'لقد قمت بإلغاء الاشتراك من الحساب بنجاح';

          setTimeout(() => {
            localStorage.clear();
            this.$router.push({ name: 'Login', query: { redirect: '/login' } });
            sessionStorage.clear();
          }, 1000);

        }
        if (response.status === 500) {
          this.errorMessage = 'فشل إلغاء اشتراك المستخدم.';
        }
        if (response.status === 400 || response.status === 401) {
          this.errorMessage = 'فشل إلغاء اشتراك المستخدم.';
        }

      } catch (error) {

        this.errorMessage = 'فشل إلغاء اشتراك المستخدم.';

      } finally {
        this.loadingDiv = false;
        this.modal = false
      }
    },

    crossGo() {
      window.history.back();
    },

  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}

@media screen and (max-width: 600px) {
  body {
    height: auto !important;
  }
}
</style>