<!-- <script setup>
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB7IBjC9UGXMbgx8HXcxqwBKllnznKlv1I",
  authDomain: "push-notification-6b085.firebaseapp.com",
  projectId: "push-notification-6b085",
  storageBucket: "push-notification-6b085.appspot.com",
  messagingSenderId: "967189097686",
  appId: "1:967189097686:web:d65dc65dedd9ec0f086d77",
  measurementId: "G-HBW0M13C63"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log('Message received. ', app, payload);
  // ...
});
getToken(messaging, { vapidKey: 'BBjPIwlR5v8B2P4z7V74W35QG-VKMZs_igLpEs3WeaO5psGJnk10fiDaj_UR6AeDJeeROkF8oFcnIk8M2XawEok' }).then((currentToken) => {
  if (currentToken) {
    console.log("token is:", currentToken)
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});


</script> -->
<!-- <script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script> -->

<template>
  <!-- Show loading spinner when isLoading is true -->
  <div v-if="isLoading" class="loading-spinner">
      <!-- You can use CSS to style your loading spinner -->
      <img src="./assets/images/cat-language-app-loading.gif" width="300">
      <p>Loading...</p>
    </div>
  <router-view v-else />
</template>

<script>
export default {
  data() {
    return {
      isLoading: true, // Initially, set loading to true
      messages: [],
    };
  },

  mounted() {
    
    // Simulate an async operation (e.g., fetching data)
    setTimeout(() => {
      this.isLoading = false; // After the async operation, set loading to false
    }, 1000); // Simulating a 2-second loading time
  },
};
</script>