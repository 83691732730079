<template>
  <div class="content signup">
    <div class="container">
      <div class="row align-items-center justify-content-center pt-4 pb-4">
        <div class="col-md-6">
          <a class="btn btn-link" href="/profile"><img src=".././assets/images/backarrow.svg"></a>
        </div>
        <div class="col-md-6 text-end dir-rtl text-primarydark">
          <h1 class="text-primarydark fw-bold nanosans">
            تعديل الملف الشخصي
          </h1>
        </div>
      </div>
      <div class="alert alert-success text-end" v-if="this.success != ''">{{ success }}</div>
      <div class="alert alert-danger text-end" v-if="this.errorMessages != ''">{{ errorMessages }}</div>
      <Form @submit="submitEmail">

        <div class="form-group mb-3 position-relative d-inline-flex align-items-center">
          <div class="picture-upload">
            <input type="file" ref="fileInput" @change="handleFileChange" />
            <img src="../assets/images/plus-round.svg" class="button" />
            <img :src="this.imageUrl" class="prof-pic" v-if="imageUrl != null">
            <img src="../assets/images/placeholder-pic.jpg" class="prof-pic" v-else>
          </div>
          <h4 class="text-primarydark fw-bold">{{ name1 }}</h4>
        </div>

        <div class="form-group mb-3 position-relative">
          <Field type="text" class="form-control passwrd text-end" placeholder="اسم جديد" v-model="name" name="newname"
            :value="name" :rules="IsnameCheck" />
          <ErrorMessage name="newname" class="error w-100 text-danger" />
        </div>
        <button type="submit" class="btn btn-main btn-lg py-3 w-100 mt-5 dir-rtl nanosans position-relative"
          :disabled="!this.notdis">
          <div class="spinner" v-if="loadingDiv"></div>
          حفظ
        </button>
      </Form>
    </div>
  </div>
</template>
<script >
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      email: '',
      newName: '',
      name: '',
      name1: '',
      selectedFile: '',
      emailsMatchMessage: '',
      success: '',
      errorMessages: '',
      imageUrl: '',
      notdis: true,
      loadingDiv: false,


    };
  },
  watch: {
    // oldEmail(newVal) {
    //   if (newVal === this.email) {
    //     this.emailsMatchMessage = '';

    //   } else {
    //     this.emailsMatchMessage = 'Emails do not match';
    //   }
    // }
  },
  mounted() {
    const dataget = JSON.parse(localStorage.getItem('userData'));
    if (dataget) {
      this.email = dataget.email
      this.imageUrl = dataget.avatar
      this.name1 = dataget.name
      this.name = dataget.name
    }
    // this.modalBadge = true;

  },
  computed: {
    // shouldShowModal() {
    //   return this.achievedBadge && this.achievedBadge.length > 0;
    // },
  },
  methods: {
    // dismissBadge(){
    //   this.modalBadge = false
    // },
    IsnameCheck(value) {
      const minLength = 1;
      const maxLength = 20;
      if (!value) {
        return 'كلمة المرور مطلوبة';
      } else if (value.length < minLength) {
        return `يجب أن يكون الاسم أكثر من 5 أحرف`;
      } else if (value.length > maxLength) {
        return `يجب ألا يتجاوز طول حقل الاسم 20 حرفًا`;
      }

      return true;

    },
    isRequired(value) {
      if (!value) {
        return 'this field is required';
      }
      return true;
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];

      if (this.selectedFile) {
        // Check if the file size is less than or equal to 2 MB
        if (this.selectedFile.size <= 2 * 1024 * 1024) {
          if (this.isImageFile(this.selectedFile)) {
            this.imageUrl = URL.createObjectURL(this.selectedFile);
            this.errorMessages = '';
          } else {
            this.errorMessages = 'الرجاء اختيار صورة صالحة';
            this.selectedFile = null; // Clear the selected file
          }
        } else {
          this.errorMessages = 'يرجى ملاحظة أن حجم الصورة يجب ألا يتجاوز 2 ميغابايت.';
          this.imageUrl = ''; // Clear the image URL
          this.selectedFile = null; // Clear the selected file
        }
      } else {
        // Display an error message if the selected file is not an image
        this.errorMessages = 'لم يتم تحديد أي صورة';
        this.imageUrl = ''; // Clear the image URL
        this.selectedFile = null; // Clear the selected file
      }
    },

    isImageFile(file) {
      // Define an array of allowed image MIME types
      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'];

      // Check if the file type is in the allowed list
      return allowedImageTypes.includes(file.type);
    },
    submitEmail() {
      this.notdis = false;
      this.loadingDiv = true;
      const formData = new FormData();
      formData.append('avatar', this.selectedFile);
      formData.append('name', this.name);

      axios
        .post('https://backend.nadyalloughat.com/api/profile/update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(response => {
          // Handle the API response here
          console.log('File uploaded successfully:', response.data);
          if (response.status === 200 || response.status === 201) {
            this.notdis = true;
            this.loadingDiv = false;
            console.log(response.data.message);
            this.success = 'تم تحديث السجل بنجاح';
            this.errorMessages = '';
            localStorage.setItem('userData', JSON.stringify(response.data.user));
            const dataget = JSON.parse(localStorage.getItem('userData'));
            if (dataget) {
              this.email = dataget.email
              this.imageUrl = dataget.avatar
              this.name = dataget.name
              this.name1 = dataget.name
            }
            this.achievedBadge = response.data.new_achievement
            // this.$router.push({ name: 'Setting', query: { redirect: '/setting' } });

          } else {
            console.log('Password Update Failed:', response.data.message);
            this.errorMessages = response.data.message;
            this.notdis = true;
            this.loadingDiv = false;
          }
        })
        .catch(error => {
          console.error('File upload error:', error);
          this.errorMessages = 'يجب أن تكون الصورة الرمزية محددة';
          this.success = '';
          this.notdis = true;
          this.loadingDiv = false;
          if (error.response.status === 413) {
            this.errorMessages = 'يرجى ملاحظة أن حجم الصورة يجب ألا يتجاوز 2 ميغابايت.';
          }

        });

      // if (this.oldEmail == this.email || this.email == null) {
      //   try {
      //     // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
      //     const response = await axios.post('', {
      //       // country: this.dialCode,
      //       email: this.newEmail,
      //     },
      //       {
      //         headers: {
      //           'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
      //           Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      //           'Content-Type': 'application/json', // Specify the content type
      //         }
      //       });

      //     if (response.status === 200 || response.status === 201) {
      //       console.log(response.data.message);
      //       this.success = response.data.message;
      //       sessionStorage.setItem('userData', JSON.stringify(response.data.user));
      //       // this.$router.push({ name: 'Setting', query: { redirect: '/setting' } });

      //     } else {
      //       console.log('Password Update Failed:', response.data.message);
      //       this.errorMessages = response.data.message;
      //     }
      //   } catch (error) {
      //     this.errorMessages = 'An error occurred during Email update.';
      //     console.error('Error during login:', error);
      //   }
      // }

    },

  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}
</style>