<template>
    <div class="main-page wizard h-100 pt-3">
        <!-- {{ questions }} -->
        <!-- {{ step }} -->

        <!-- {{ results }} -->

        <div class="container" v-if="!resultPage">
            <div class="cross-sign pb-4">
                <button class="btn btn-link" @click="crossGo"><img src="../.././assets/images/cross-icon.svg"></button>
            </div>
            <div class="row">
                <div class="col-md-12 position-relative">
                    <div class="progressbar-outer dir-rtl">
                        <div class="progressbar" :style="'width:' + progressbarWidth + '%'"></div>
                    </div>
                    <!-- <div class="progress-steps">{{ stepTemp }}/{{ totalSteps }}</div> -->
                </div>
            </div>
        </div>
        <div class="container" v-if="!resultPage">
            <div class="row justify-content-center align-items-center">
                <div v-if="loadingDiv" class="loading-spinner">
                    <div class="spinner"></div>
                </div>
                <div class="col-12 mx-auto" v-else>
                    <div class="step-wizard pt-4">

                        <form @submit.prevent.stop="submitForm">
                            <!-- {{ questions }} -->
                            <template v-for="(value, i) in questions" :key="i">
                                <!-- {{ value.question_options[0].is_correct }} -->
                                <div v-if="step === value.index">

                                    <div class="row">
                                        <div class="col-12 text-center dir-rtl text-primarydark">
                                            <h5 class="text-primarydark">{{ value.question_title }}</h5>
                                            <h1 class="text-primarydark fw-bold nanosans pt-3 mb-3"
                                                v-if="value.question_types.slug != 'choose-name' && value.question_types.slug != 'choose-correct-translation' && value.question_types.slug != 'fill-in-the-blanks'">
                                                <input type="hidden" :value="value.question" id="myHiddenField" />
                                                <span v-if="isArabic[i] !== 'isArabic'">
                                                    <button class="btn btn-link"
                                                        @click.prevent="playSound(value.audio_file)"
                                                        v-if="value.question_voice_toggle === 1">
                                                        <img src="../.././assets/images/sound-play.svg">
                                                    </button>
                                                </span>

                                                <span>{{ value.question
                                                    }}</span>
                                            </h1>
                                            <h1 dir="auto" class="text-primarydark fw-bold nanosans pt-3 mb-3"
                                                v-if="value.question_types.slug == 'choose-correct-translation' && value.question_voice_toggle === 1">
                                                <input type="hidden" :value="value.question" id="myHiddenField" />

                                                <span v-if="value.show_text == '1'">{{ value.question }}</span>
                                                <span>
                                                    <button class="btn btn-link"
                                                        @click.prevent="playSound(value.audio_file)">
                                                        <img src="../.././assets/images/sound-play.svg"></button>
                                                </span>
                                            </h1>
                                            <h1 dir="auto" class="text-primarydark fw-bold nanosans pt-3 mb-3"
                                            v-if="value.question_types.slug == 'choose-correct-translation' && value.question_voice_toggle === 0">
                                                <input type="hidden" :value="value.question" id="myHiddenField" />

                                                <span>{{ value.question }}</span>

                                            </h1>
                                            <div v-if="value.question_types.slug == 'choose-name'"
                                                class="mb-3 c-imagechoose"><img :src="value.file" alt="Item Image">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="quiz-options row"
                                        :class="value.question_types.slug == 'voice-translation-with-option-text' ? 'dir-rtl' : '' || value.question_types.slug == 'write-name' ? 'height-custom' : ''"
                                        v-if="value.question_types.slug != 'choose-correct-translation' && value.question_types.slug != 'tap-the-pairs' && value.question_types.slug != 'fill-in-the-blanks'">
                                        <div class="col-6 col-md-6 mx-auto" v-for="(items, i) in value.question_options"
                                            :key="i" :class="changeColor">
                                            <div class="box-shaper"
                                                v-if="value.question_types.slug != 'write-name' && value.question_types.slug != 'voice-translation-with-option-image'"
                                                :class="'iscorrect-' + items.is_correct">

                                                <input type="radio" :id="items.option + '_' + items.question_id"
                                                    name="answers"
                                                    @change='onToggle(items.question_id, items.is_correct, items.option, i, items.option_voice_toggle, items.audio_file)'
                                                    :value="items.option + '_' + items.question_id"
                                                    v-model="selectoptions" :disabled="this.changeColor != ''" />

                                                <label :class="this.changeColor != '' ? 'nothover' : ''"
                                                    :for="items.option + '_' + items.question_id">
                                                    {{ items.option }}
                                                </label>
                                            </div>

                                            <div class="box-shaperimg" v-if="value.question_types.slug == 'write-name'">
                                                <div class="box">
                                                    <img :src="items.option_file">
                                                </div>
                                                <p>{{ items.option }}</p>

                                                <div>
                                                    <input type="text" v-model="writename" placeholder="Write..."
                                                        @change='handleWritName(items.question_id, items.correct_answer)'
                                                        :disabled="this.changeColor != ''" @keydown.enter.prevent="">
                                                </div>
                                                <div class="mt-4" v-if="changeColor === 'check-red'">
                                                    <span class="btn btn-green">{{ items.correct_answer }}</span>
                                                </div>
                                            </div>
                                            <div class="box-shaper"
                                                v-if="value.question_types.slug == 'voice-translation-with-option-image'"
                                                :class="'iscorrect-' + items.is_correct">
                                                <input type="radio" :id="'imageoptic_' + items.id" name="answers"
                                                    @change='onToggled(items.question_id, items.is_correct, items.option_voice_toggle)'
                                                    :value="items.option + items.id" v-model="selectoptions"
                                                    :disabled="this.changeColor != ''" />
                                                <label :class="this.changeColor != '' ? 'nothover' : ''"
                                                    :for="'imageoptic_' + items.id">
                                                    <img :src="items.option_file" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="value.question_types.slug == 'choose-correct-translation'">
                                        <CheckStence :quizwords="value.question_options" :wordvalue="value"
                                            @correct-check="handleAllChecked" @notdis="checkbtndis"
                                            :isAnsChecked="this.isAnsChecked" @checBtn="checBtnis" />

                                    </div>
                                    <div v-if="value.question_types.slug == 'fill-in-the-blanks'">
                                        <FillBlank :quizwords="value.question_options" :wordvalue="value"
                                            @correct-check="handleAllChecked" @notdis="checkbtndis"
                                            :isAnsChecked="this.isAnsChecked" @checBtn="checBtnis" />

                                    </div>
                                    <div class="quiz-options row height-custom"
                                        v-if="value.question_types.slug == 'tap-the-pairs'">
                                        <WordsMatch :quizoptions="value.questionOptions"
                                            :quizoptionsecond="value.questionOptionsSecond"
                                            @all-matched="handleAllMatched" @checBtn="checBtnis" :questionid="value.id"
                                            :allmatched="this.isAllMatched" />

                                    </div>

                                    <div class="row mt-5"
                                        v-if="isAnsChecked && value.question_types.slug != 'tap-the-pairs' && value.question_types.slug != 'choose-correct-translation' && value.question_types.slug != 'fill-in-the-blanks'">
                                        <div class="col-md-12 col-12 text-center">

                                            <button type="button"
                                                @click="checkAnswers(value.id, value.question_options[i].is_correct)"
                                                class="btn btn-main btn-lg py-3 w-100" :disabled="!notdis">
                                                تحقق
                                            </button>
                                        </div>
                                    </div>

                                    <!-- correct and incorrect popups -->
                                    <div class="correct-popup">
                                        <div class="modal fade"
                                            :class="{ 'show': modal, 'success': changemColor, 'badsuccess': !changemColor }"
                                            id="exampleModal" tabindex="-1" role="dialog"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true"
                                            :style="modal === true ? 'display: flex; padding-right: 17px;' : ''">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">

                                                    <div class="modal-body text-center pt-5 pb-5">
                                                        <h3 class="nanosans fw-bold" v-if="changemColor">إجابتك صحيحة!
                                                        </h3>
                                                        <h3 class="nanosans fw-bold" v-else>إجابتك غير صحيحة!</h3>
                                                        <div class="correct-ans" v-if="changemColor">

                                                            <div class="write-name"
                                                                v-if="value.question_types.slug === 'write-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <div class="write-answ">
                                                                        <div class="inside-img">
                                                                            <img :src="items.option_file"
                                                                                alt="Item Image">
                                                                        </div>
                                                                        <span>{{ items.option }}</span>
                                                                    </div>
                                                                    <div class="writed-answ">
                                                                        <span :class="'leseng-word'">{{
                                                                            items.correct_answer }}</span>

                                                                        <button class="btn btn-link speakwordds"
                                                                            @click.prevent="speakwordds(items.audio_file)"
                                                                            v-if="items.option_voice_toggle === 1">
                                                                            <img
                                                                                src="../../assets/images/sound-wplay.svg">
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div v-if="value.question_types.slug == 'choose-name'"
                                                                class="mb-0 c-imagechoose img-ans">
                                                                <img :src="value.file" alt="Item Image">
                                                            </div>

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-text'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <span v-if="items.is_correct === 1"
                                                                        class="leseng-word">{{ items.option }}
                                                                    </span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-image'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <span v-if="items.is_correct === 1"
                                                                        class="leseng-word"> {{ value.question }}
                                                                    </span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(value.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <span class="leseng-word"
                                                                        v-if="items.is_correct === 1">{{ items.option
                                                                        }}</span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div v-if="value.question_types.slug != 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <img :src="items.option_file" alt=""
                                                                        v-if="items.is_correct === 1" height="54">
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="text-center" v-else>

                                                            <div class="write-name"
                                                                v-if="value.question_types.slug === 'write-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <div class="write-answ">
                                                                        <div class="inside-img">
                                                                            <img :src="items.option_file"
                                                                                alt="Item Image">
                                                                        </div>
                                                                        <span>{{ items.option }}</span>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div v-if="value.question_types.slug == 'choose-name'">
                                                                <img :src="value.file" alt="Item Image">
                                                            </div>
                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name' && value.question != null || value.question_types.slug ===
                                                                    'voice-translation-with-option-image' || value.question_types.slug === 'voice-translation-with-option-text'">
                                                                {{
                                                                    value.question }}</div>

                                                        </div>
                                                    </div>
                                                    <div class="modal-footer text-center d-inline-block">
                                                        <div v-if="!changemColor">
                                                            <h5 class="nanosans">اجابة صحيحة :</h5>


                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'write-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <span class="leseng-word">{{ items.correct_answer
                                                                        }}</span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <!-- <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i"><span v-if="items.is_correct === 1">{{
                                                                        items.option }}</span></div>
                                                            </div>

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-text'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i"><span v-if="items.is_correct === 1">{{
                                                                        items.option }}</span></div>
                                                            </div> -->

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-text'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <!-- <code>{{ items }}</code> -->
                                                                    <span v-if="items.is_correct === 1"
                                                                        class="leseng-word">{{ items.option }}
                                                                    </span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <span class="leseng-word"
                                                                        v-if="items.is_correct === 1">{{ items.option
                                                                        }}</span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-image'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <div class="text-ansc"
                                                                        v-if="items.is_correct === 1">
                                                                        <img :src="items.option_file" alt=""
                                                                            height="54">
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="row"
                                                            v-if="!isAnsChecked || value.question_types.slug == 'tap-the-pairs'">

                                                            <div class="col-md-12 col-12 text-center pb-3">
                                                                <button type="button" @click="wizardNext(value.id)"
                                                                    class="btn btn-main btn-lg py-3 w-100"
                                                                    v-if="!isLastQuestion(i) && questions.length > 0"
                                                                    :disabled="!notdis && !isAllMatched">
                                                                    التالي
                                                                </button>

                                                                <button type="submit" @click="submitForm"
                                                                    class="btn btn-main btn-lg py-3 w-100" v-else
                                                                    :disabled="!notdis && !isAllMatched">
                                                                    <div class="spinner" v-if="loadingDiv"></div>
                                                                    تابع
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <!-- <button type="button" class="btn btn-main btn-lg py-3 w-100"
                                                            @click="wizardNext(value.id)">التالي</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- correct and incorrect popups -->

                                    <div class="row mt-5"
                                        v-if="!isAnsChecked || value.question_types.slug == 'tap-the-pairs'">
                                        <div class="col-md-12 col-12 text-center">
                                            <button type="button" @click="wizardNext(value.id)"
                                                class="btn btn-main btn-lg py-3 w-100"
                                                v-if="!isLastQuestion(i) && questions.length > 0"
                                                :disabled="!notdis && !isAllMatched">
                                                التالي
                                            </button>

                                            <button type="submit" @click="submitForm"
                                                class="btn btn-main btn-lg py-3 w-100" v-else
                                                :disabled="!notdis && !isAllMatched">
                                                <div class="spinner" v-if="loadingDiv"></div>
                                                تابع
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center wizard-last container h-100" v-if="resultPage">
            <div class="container h-100">
                <div class="row align-items-center d-flex">
                    <div class="col-12">
                        <img src="../.././assets/images/apple.svg" width="90">
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 1">
                            لقد أكملت الدرس الأول من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 2">
                            لقد أكملت الدرس الثاني من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 3">
                            لقد أكملت الدرس الثالث من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 4">
                            لقد أكملت الدرس الرابع من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 5">
                            لقد أكملت الدرس الخامس من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 6">
                            لقد أكملت الدرس السادس من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 7">
                            لقد أكملت الدرس السابع من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 8">
                            لقد أكملت الدرس الثامن من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 8">
                            لقد أكملت الدرس التاسع من درس الفاكهة!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3 dir-rtl" v-if="lessonnumer === 8">
                            لقد أكملت الدرس العاشر من درس الفاكهة!
                        </h2>
                        <h1 class="text-secondary fw-bold pt-3 mb-3">{{ toArabicNumber(correctCounts) }} / {{
                            toArabicNumber(totalSteps) }}
                        </h1>
                        <h3 class="text-primarydark fw-normal nanosans pt-3 mb-3 dir-rtl">جيد!</h3>
                    </div>
                    <div class="col-lg-7 mx-auto">
                        <img src="../../assets/images/cat-language-app-loading.gif" class="w-44">
                        <div class="d-md-grid gap-2 d-md-flex justify-content-center">
                            <router-link to="javascript:;" class="btn btn-main btn-lg py-3 w-80" @click="crossGo">
                                الدرس التالي
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <!-- {{ results }} -->
    </div>
</template>

<script>
import WordsMatch from "@/components/WordsMatch";
import CheckStence from "@/components/CheckStence";
import FillBlank from "@/components/FillBlank";

import '../../assets/resources/js/notify';
// const VueTelInput = window['vue-tel-input'].VueTelInput;
// Vue.component('vue-tel-input', VueTelInput.VueTelInput)
import axios from 'axios';
// import quizes from "../.././assets/quiz-landing";
// import contents from "../.././assets/content-templates.js";

import trumpetSfx1 from '../../assets/audio/hoesound.mp3';
import correctSound1 from '../../assets/audio/correct.wav';
import wrongSound1 from '../../assets/audio/wrong.wav';
import finishLevel1 from '../../assets/audio/finishlevel.wav';

export default {
    components: {
        WordsMatch,
        CheckStence,
        FillBlank
    },
    data() {
        return {
            lessonnumer: `${localStorage.getItem('lessonNumber')}`,
            catName: `${localStorage.getItem('categoryname')}`,
            resultPage: false,
            loadingDiv: true,
            timestamp: '',
            progressbarWidth: 15,
            totalSteps: '',
            step: 1,
            changemColor: false,
            quizSelected: [],
            showField: false,
            showTemplate: false,
            questions: '',
            words: [],
            modal: false,
            writename: '',
            corectImage: '',
            sentenceMatch: [],
            selectedArabicWord: [], // Arabic selected option
            selectedEnglishWord: [], // English selected option
            changeColor: '',
            correctCounts: '',
            selectoptions: '',
            // userResponses: [],
            results: [],
            userId: 0,
            headText: '',
            isArabic: [],
            notdis: false,
            isAllMatched: false,
            stepIndex: 0,
            wrongAnswers: [],
            isAnsChecked: true,
            custIndex: 0,
            hasMistak: null,
            currentAudio: null,
            correctSound: null,
            wrongSound: null,
            finishLevel: null,
            trumpetSfx: null,

        }
    },
    watch: {
        writename(valNew) {
            if (valNew.length > 0) {
                this.notdis = true;
            } else {
                this.notdis = false;
            }
        }
    },
    created() {
        setInterval(this.getNow, 1000);

    },
    mounted() {
        this.fetchData();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.userId = dataget.id
        }
        this.correctSound = new Audio(correctSound1);
        this.wrongSound = new Audio(wrongSound1);
        this.finishLevel = new Audio(finishLevel1);
        this.trumpetSfx = new Audio(trumpetSfx1);

        this.correctSound.load();
        this.wrongSound.load();
        this.finishLevel.load();
        this.trumpetSfx.load();

    },

    methods: {
        toArabicNumber(num) {
            const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
            return num
                .toString()
                .split('')
                .map(digit => arabicDigits[digit])
                .join('');
        },
        checkbtndis(buttonSet) {
            this.notdis = buttonSet;
            // console.log(this.notdis);

        },
        checBtnis(value) {
            this.isAnsChecked = value;
        },
        handleAllMatched(isAllMatched, correct, isCoreect) {
            this.isAllMatched = isAllMatched;

            if (correct == 1) {
                this.results.push({ question_id: isCoreect, is_correct: correct, correct: isAllMatched });
                // this.wrongAnswers.push({ question_id: QuesId, is_correct: isCoreect, correct: true });

            } else {
                this.results.push({ question_id: isCoreect, is_correct: 0, correct: false });
                // this.wrongAnswers.push({ question_id: QuesId, is_correct: 0, correct: false });
            }

        },
        handleAllChecked(isCoreecty, QuesIdd) {
            if (isCoreecty == 1) {
                // this.wrongAnswers.push({ question_id: QuesIdd, is_correct: isCoreecty, correct: true });
                const existingItem = this.results.find(item => item.question_id === QuesIdd);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCoreecty;
                    existingItem.correct = isCoreecty === 1;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: QuesIdd, is_correct: isCoreecty, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: QuesIdd, is_correct: 0, correct: false });
                const existingItem = this.results.find(item => item.question_id === QuesIdd);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCoreecty;
                    existingItem.correct = isCoreecty === 0;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: QuesIdd, is_correct: 0, correct: false });
                }
            }
        },

        checkLang(vl) {
            const arabicPattern = /[\u062a\u0641\u0627\u062d\u0629]/;
            if (vl !== null) {
                let ar = arabicPattern.test(vl)
                this.isArabic.push((ar == true) ? 'isArabic' : 'isEnglish');
            }
        },
        // selectWord(questionId, englishWord) {
        //     console.log("Selected question_id:", questionId);
        //     console.log("Selected English Word:", englishWord, this.selectedArabicWord);

        //     if (englishWord == this.selectedArabicWord || this.selectedArabicWord == englishWord) {
        //         console.log(this.changeColor);
        //         this.results.push({ question_id: questionId, is_correct: 1, correct: true });
        //     } else {
        //         // this.changeColor = 'check-red';
        //         console.log('anwser not right', this.changeColor);
        //         this.results.push({ question_id: questionId, is_correct: 0, correct: false });
        //     }
        // },

        onToggle(questionId, isCorrect, itemValue, i, voiceopt, voicsound) {
            console.log(i, voiceopt);
            this.notdis = true;
            // let results = []
            if (isCorrect == 1) {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 1;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 0;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                }
            }


            if (this.currentAudio) {
                this.currentAudio.pause();
                this.currentAudio.currentTime = 0;
            }
            if (voicsound) {
                this.currentAudio = new Audio(voicsound);
                this.currentAudio.play();

            }

        },
        onToggled(questionId, isCorrect) {
            // console.log(itemValue);
            this.notdis = true;
            // let results = []
            if (isCorrect == 1) {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 1;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 0;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                }
            }
        },

        handleWritName(questionId, correctAnswer) {
            const userInput = this.writename;
            const userInputLower = userInput.toLowerCase();
            const correctAnswerLower = correctAnswer.toLowerCase();

            // console.log('check before', userInputLower)
            if (correctAnswerLower === userInputLower) {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: 1, correct: true });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = 1;
                    existingItem.correct = true;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: 1, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: 0, correct: false });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = 0;
                    existingItem.correct = false;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: 0, correct: false });
                }


            }
        },

        getNow: function () {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + ' ' + time;
            this.timestamp = dateTime;
        },
        async fetchData() {
            const chapterId = this.$route.params.cid;
            const unitId = this.$route.params.uid;

            try {
                const response = await axios.get('https://backend.nadyalloughat.com/api/quiz/' + unitId + '/' + chapterId, {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',

                    },

                });

                if (Array.isArray(response.data.data)) {
                    this.questions = response.data.data.map((value, index) => {
                        value.index = index + 1;
                        const arabicPattern = /[\u062a\u0641\u0627\u062d\u0629]/;
                        arabicPattern.test(value.question)
                        this.checkLang(value.question);
                        return value;

                    });
                    // this.words = response.data.data.map((value) => {
                    //     if (value.question_options && typeof value.question_options[0].option === 'string' && value.question_types.slug === 'choose-correct-translation') {
                    //         return value.question_options[0].option.split(',');
                    //     } else {
                    //         return [];
                    //     }
                    // });

                    // this.words = this.words.flat();

                    this.totalSteps = this.questions.length;

                } else {
                    console.error('Response data is not an array:', response.data.data);
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }
        },
        playSound(sound) {

            if (sound) {
                var audio = new Audio(sound)
                audio.play();
            }
        },

        submitForm() {
            const chapterId = this.$route.params.cid;
            const unitId = this.$route.params.uid;

            this.loadingDiv = true;
            this.notdis = false;
            const hasIncorrectAnswerss = this.results.some(answer => answer.is_correct === 0);
            if (hasIncorrectAnswerss) {
                this.hasMistak = 1

            } else {
                this.hasMistak = 0;

            }
            const lastResponsesByUniqueId = {};
            this.results.forEach(response => {
                const id = response.question_id;
                if (!lastResponsesByUniqueId[id]) {
                    lastResponsesByUniqueId[id] = response;
                } else if (response.correct) {
                    lastResponsesByUniqueId[id] = response;
                }
            });
            const lastResponses = Object.values(lastResponsesByUniqueId);

            let correctCount = lastResponses.filter(question => question.is_correct === 1).length;
            this.correctCounts = correctCount;


            // const lessonId = this.$route.params.id;

            let payload = {
                user_id: this.userId,
                unit_id: unitId,
                chapter_id: chapterId,
                // category_lesson_id: lessonId,
                started_at: `${sessionStorage.getItem('startquiz')}`,
                completed_at: this.timestamp,
                quiz_data: lastResponses,
                has_mistake: this.hasMistak,
            }

            const headers = {
                'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json', // Specify the content type
            };
            const requestConfig = {
                method: 'post', // Use the POST method
                url: 'https://backend.nadyalloughat.com/api/quiz/process', // Define the API endpoint URL
                headers: headers, // Set the headers
                data: payload, // Attach the payload data
            };

            // form post
            axios(requestConfig)
                .then((response) => {
                    // Handle the response here
                    if (response.data.error) {
                        // Handle the error condition
                        this.errorMessage = response.data.message; // Assuming error message is returned in the response
                    } else {
                        // The request was successful, set resultPage to true or perform other actions
                        this.resultPage = true;
                        // var audiolevel2 = new Audio(finishLevel)
                        this.finishLevel.currentTime = 0;
                        this.finishLevel.play();
                    }
                })
                .catch((error) => {
                    // Handle any errors that occur during the request
                    console.error('Error sending form data:', error);
                })
                .finally(() => {
                    // Perform actions that should occur regardless of success or failure
                });

        },
        clearwritename() {
            this.writename = '';
        },
        disablebuttonany() {
            this.notdis = false;
        },
        speakwordds(name) {

            if (name) {
                var audio = new Audio(name);
                audio.play();
            }

        },
        clearSelectedOption() {
            this.selectoptions = null;
        },
        // checkResult() {
        //     let qust = this.questions;
        //     let userAns = this.userResponses;
        //     for (let i = 0; i < qust.length; i++) {
        //         if (qust[i]?.id !== undefined && userAns[i]?.id !== undefined) {
        //             console.log("Question ID:", qust[i].id, "User Response ID:", userAns[i].id);
        //             if (qust[i].question_options[i].is_correct === userAns[i].options) {
        //                 console.log("Data Api:", qust[i].question_options.is_correct, "User Response:", userAns[i].options);
        //             }

        //         }
        //     }

        // },
        checkAnswers(questionID, isCorrect) {
            if(this.currentAudio){
                this.currentAudio.pause();
            }
           
            const resultIndex = this.results.findIndex(item => item.question_id === questionID);

            if (resultIndex !== -1) {
                console.log(isCorrect);
                this.modal = true;

                if (this.results[resultIndex].is_correct === 1) {
                    this.changeColor = 'check-green';
                    this.changemColor = true;

                    // var audioCorrect = new Audio(correctSound);
                    this.correctSound.currentTime = 0;
                    this.correctSound.play();

                } else {
                    this.changeColor = 'check-red';
                    this.changemColor = false;
                    // var audiowCorrect = new Audio(wrongSound);
                    this.wrongSound.currentTime = 0;
                    this.wrongSound.play();
                }

                this.isAnsChecked = false;
            } else {
                // Handle the case where the questionId is not found in the results
                console.error('Question not found in results');
            }



        },
        checkedEl(el) {
            this.wizard.plateforms.push(el);
        },
        wizardFirstStep() {
            this.step = 1;
            this.resultPage = false;
            // this.stepTemp = 1;
            // this.progressbarWidth += 30;
        },
        playProgressSound() {
            // Create an Audio object with the path to your audio file
            // const audio = new Audio(trumpetSfx);

            // Play the audio
            this.trumpetSfx.currentTime = 0;
            this.trumpetSfx.play();
        },
        wizardNext(screenId) {
            // this.notdis = false;
            this.modal = false;
            this.isAllMatched = false;
            this.isAnsChecked = true;
            this.step++;
            // this.stepTemp++

            // console.log('question lenght',this.questions.length)
            if (this.step < this.totalSteps) {
                this.progressbarWidth = ((this.step) / this.totalSteps) * 100;
            }
            // if (this.questions.length > 0) {
            //     this.progressbarWidth = (this.results.length + 1) * 11;
            //     this.playProgressSound();
            // } else {
            //     this.progressbarWidth = 100;
            // }
            if (this.questions.length > 0) {
                this.playProgressSound();
            }


            if (this.changeColor != '') {
                this.changeColor = ''
            }

            // const indexss = this.stepIndex++;
            // console.log(i);
            // const questionToRemove = this.questions.find(question => question.id === screenId);

            const existingItem = this.results.find(item => item.question_id === screenId);
            const isccorrect = existingItem.is_correct;

            if (isccorrect === 0) {
                // this.stepTemp - 1;
                // this.progressbarWidth += 0;
                const indexToMove = this.questions.findIndex(item => item.id === screenId);

                if (indexToMove !== -1) {

                    const movedItem = this.questions.splice(indexToMove, 1)[0];

                    if (movedItem.movedCount === undefined) {
                        movedItem.movedCount = 1;
                    } else {
                        movedItem.movedCount++;
                    }

                    if (movedItem.movedCount < 2) {

                        const maxIndex = Math.max(...this.questions.map(question => question.index));
                        const newIndex = isNaN(maxIndex) ? 1 : maxIndex + 1;
                        movedItem.index = newIndex;
                        this.questions.push(movedItem);
                        // this.progressbarWidth = (this.results.length + 1) * 11;
                        this.notdis = false;

                    }
                }
                this.progressbarWidth += 1;
            }

            if (isccorrect === 1) {
                // this.progressbarWidth = ((this.step + 1) / this.totalSteps) * 50;
                // this.stepTemp++
                this.notdis = false;
                // console.log(this.step, this.totalSteps)
                const indexToMove = this.questions.findIndex(item => item.id === screenId);
                if (indexToMove !== -1) {
                    this.questions.splice(indexToMove, 1);
                }
                this.progressbarWidth = ((this.step) / this.totalSteps) * 100;
            }

            this.clearSelectedOption();
            this.clearwritename();
            this.disablebuttonany();
            // console.log('Button Disabled:', this.notdis);
        },
        isLastQuestion(i) {
            // return i === this.questions.length - 1;
            if (i === this.questions.length - 1) {
                this.progressbarWidth = 100;
                return true;
            } else {
                return false;
            }

        },
        showHidefield(item) {
            if (item == 'true') {
                this.showField = true;
            }
        },
        crossGo() {
            window.history.back();
        }

    }
}
</script>