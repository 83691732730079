import { ref, onMounted } from 'vue';
import Pusher from 'pusher-js';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

const messages = ref([]);
// const toast = toast();

const pusher = new Pusher('a46ed5ced98d07e38ce0', {
    cluster: 'us2'
});

const channel = pusher.subscribe('my-channel');

channel.bind('my-event', (data) => {
    const dataget = JSON.parse(localStorage.getItem('userData'));

    if (dataget) {

        if (data.userId) {

            console.log('user dataget ID', dataget.id)
            console.log('user data ID', data.userId)
            if (data.userId === dataget.id) {

                messages.value.push(JSON.stringify(data));
                showNotification(data.message);

                const lastNotificationDate = sessionStorage.getItem('lastNotificationDate');
                if (!lastNotificationDate) {
                    const currentDate = new Date();
                    sessionStorage.setItem('lastNotificationDate', currentDate.toISOString());
                }

                console.log('message', messages)

            }

        } else {
            // console.log('user data', dataget.id)
            console.log('User is no ID');

            messages.value.push(JSON.stringify(data));
            showNotification(data.message);
            
            const lastNotificationDate = sessionStorage.getItem('lastNotificationDate');
            if (!lastNotificationDate) {
                const currentDate = new Date();
                sessionStorage.setItem('lastNotificationDate', currentDate.toISOString());
            }
        }
    }


});

onMounted(() => {
    console.log('message', messages)
});
const showNotification = (message) => {
    toast.success(message, {
        position: 'top-right',
        duration: 5000,
        hasIcon: true,
        rtl: true,
        // autoClose: false,
        // icon: 'check',
    });
};
// toast.success('Hello World.\n I am <b>Tom</b>', {
//     autoClose: false,

// });