<template>
  <div class="quiz-options row">
    <div class="col-md-12">
      <!-- {{ quizwords }} -->
      <!-- {{ cisAllMatched }} -->
      <!-- {{ wordvalue.id }} -->
      <div class="sentence-fill" :class="changeColor">
        <h5 class="text-primarydark">
          <!-- {{  }} -->

          <span class="checkwordsen" v-for="(wording, index) in selectedWords.words" :key="index">
            {{ wording }}
          </span>
        </h5>
        <h5 class="text-primarydark check-green" v-if="changeColor === 'check-red' && showAfterDelay">
          <span class="checkwordsen">
            {{ correcAnswers }}
          </span>
        </h5>
      </div>
    </div>
    <!-- {{ quizwords }} -->
    <ul class="choos-trans check-sentence">
      <li v-for="(word, i) in quizwords" :key="i">
        <!-- {{ word }} -->
        <div class="box-shaper">
          <input type="checkbox" :id="word.option + i" :name="word.option + i" v-model="sentenceMatch[i]" :value="word.option"
            @change='onSentence(wordvalue.id, wordvalue.question_options[0].correct_answer, i, word.option, word.audio_file)'
            :disabled="!cisAllMatched" />
          <label class="" :for="word.option + i">
            {{ word.option }}
          </label>
        </div>
      </li>
    </ul>
    <!-- this is checked: {{isAnsChecked }} -->

  </div>
  <div class="row mt-5" v-if="isAnsChecked">
    <div class="col-md-12 col-12 text-center">
      <button type="button" @click="checkAnswers(wordvalue.id, wordvalue.question_options[0].correct_answer)"
        class="btn btn-main btn-lg py-3 w-100" :disabled="!this.notdis && !isAllMatched">
        تحقق
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['quizwords', 'wordvalue', 'isAnsChecked'],
  data() {
    return {
      sentenceMatch: [],
      changeColor: '',
      notdis: false,
      cisAllMatched: true,
      correcAnswers: [],
      showAfterDelay: false,
      words: [],
      selectedWords: { words: [] }, // Initialize selectedWords with an empty array
      currentAudio1: null,

    };
  },
  emits: ['correct-check', 'notdis', 'checBtn'],
  watch: {
    wordvalue: {
      handler: function (newVal, oldVal) {
        // Reset sentenceMatch when wordvalue changes
        this.sentenceMatch = [];
        this.changeColor = '';
        this.cisAllMatched = true;
        console.log(newVal, oldVal)
      },

      deep: true,
    },
    'wordvalue.id': function (newId, oldId) {
      if (newId !== oldId) {
        this.selectedWords = { words: [] };
        this.notdis = false;
      }
    },
  },
  // computed: {
  //   splitOptions() {
  //     if (this.quizwords && this.quizwords.length > 0) {
  //       const options = this.quizwords[0].option.split(',');
  //       return options.map(option => option.trim());
  //     }
  //     return [];
  //   }
  // },
  methods: {
    onSentence(questionId, correctAnswer, index, wordvaluee, voicesound) {

      // if (this.speechSynthesisInstance) {
      //   window.speechSynthesis.cancel();
      //   this.speechSynthesisInstance = null;
      // }

      console.log(questionId, correctAnswer, index, wordvaluee)


      // Clear selectedWords if the questionId changes
      if (this.wordvalue.id !== questionId) {
        this.selectedWords = [];

      }

      if (this.sentenceMatch[index]) {

        if (this.currentAudio1) {
          // Pause the current audio and reset it
          this.currentAudio1.pause();
          this.currentAudio1.currentTime = 0;
        }

        if (voicesound) {
          // Create a new audio instance and play it
          this.currentAudio1 = new Audio(voicesound);
          this.currentAudio1.play();
        }

        this.selectedWords.words.push(wordvaluee);
        console.log('check word', this.selectedWords.words)

      } else {
        const wordIndex = this.selectedWords.words.indexOf(wordvaluee);
        if (wordIndex !== -1) {
          this.selectedWords.words.splice(wordIndex, 1);
        }
      }

      if (this.selectedWords.words.length > 0) {
        this.notdis = true;
        this.$emit('notdis', true);
        console.log(index, correctAnswer);
      } else {
        this.notdis = false;
      }
    },

    checkAnswers(questionId, correctAnswer) {
      this.$emit('checBtn', false);
        if (this.selectedWords && this.selectedWords.words.length > 0) {


        const sentence = this.selectedWords.words.join(" ").toLowerCase();
        

        const wordsInSentence = this.wordvalue.question_options[0].correct_answer.toLowerCase();

        // console.log('check correct sentence', this.wordvalue.question_options[0].correct_answer);
        
        this.correcAnswers = wordsInSentence;
        // console.log('after condition', sentence)
        setTimeout(() => {
          this.showAfterDelay = true;
        }, 500);

        this.cisAllMatched = false;

        if (sentence === correctAnswer.toLowerCase()) {
          this.changeColor = 'check-green';
          this.$emit('correct-check', 1, questionId);
        } else {
          this.changeColor = 'check-red';
          this.$emit('correct-check', 0, questionId);
        }
      } else {
        console.error("selectedWords is undefined or does not have a 'words' property");

      }


    },
  },
};
</script>
