<template>
  <div class="content signup">
    <div>
      <div class="row align-items-center justify-content-center pt-4 pb-4">
        <div class="col-md-2">
          <a href="/setting" class="btn btn-link"><img src=".././assets/images/backarrow.svg"></a>
        </div>
        <div class="col-md-10 text-end dir-rtl text-primarydark">
          <h1 class="text-primarydark fw-bold nanosans">
            تغيير البريد الإلكتروني الخاص بك
          </h1>
        </div>
      </div>
      <div class="alert alert-success text-end" v-if="this.success != ''">{{ success }}</div>
      <div class="alert alert-danger text-end" v-if="this.errorMessages != ''">{{ errorMessages }}</div>

      <Form @submit="submitEmail">
        <div class="form-group mb-3 position-relative text-end" v-if="emailTrue">

          <Field type="email" class="form-control passwrd text-end" placeholder="البريد الإلكتروني الحالي "
            v-model="oldEmail" name="oldemail" :rules="isRequired" required />
          <ErrorMessage name="oldemail" class="error w-100 text-danger" />
          <div v-if="this.emailsMatchMessage != ''" class="text-danger">{{ emailsMatchMessage }}</div>
        </div>
        <div class="form-group mb-3 position-relative text-end">
          <Field type="email" class="form-control passwrd text-end" placeholder="البريد الإلكتروني الجديد"
            v-model="newEmail" name="new email" :rules="isRequired" required />
          <ErrorMessage name="new email" class="error w-100 text-danger" />
        </div>
        <button type="submit" class="btn btn-main btn-lg py-3 w-100 mt-5 dir-rtl nanosans">إحفظ</button>
      </Form>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      email: '',
      oldEmail: '',
      newEmail: '',
      emailsMatchMessage: '',
      success: '',
      errorMessages: '',
      emailTrue: true

    };
  },
  watch: {
    oldEmail(newVal) {
      if (newVal != this.email) {
        this.emailsMatchMessage = 'البريد الإلكتروني الحالي غير صالح';
      }
      else {
        this.emailsMatchMessage = '';
      }
    }
  },
  mounted() {
    const dataget = JSON.parse(localStorage.getItem('userData'));
    if (dataget) {
      this.email = dataget.email
    }
    if (this.email === null) {
      this.emailTrue = false
    }

  },
  methods: {
    isRequired(value) {
      if (!value) {
        return 'البريد الإلكتروني مطلوب';
      }
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return 'يجب أن يكون هذا الحقل بريدًا إلكترونيًا صالحًا';
      }

      return true;
    },
    async submitEmail() {
      // console.log('already ena', this.email);

      if (this.oldEmail == this.email || this.email == null) {
        try {
          // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
          const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
            // country: this.dialCode,
            email: this.newEmail,
          },
            {
              headers: {
                'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json', // Specify the content type
              }
            });

          if (response.status === 200 || response.status === 201) {
            console.log(response.data.message);
            this.success = 'تم تحديث السجل بنجاح';
            this.errorMessages = '';
            localStorage.setItem('userData', JSON.stringify(response.data.user));
            this.$router.push({ name: 'Setting' }).then(() => {
              this.$router.go(0); // This will reload the page after navigating to 'Setting'
            });

          } else {
            console.log('Password Update Failed:', response.data.message);
            this.errorMessages = response.data.message;
          }
        } catch (error) {
          if (error.response.status === 400) {
            this.errorMessages = 'لقد تم أخذ البريد الإلكتروني بالفعل';
          } else {
            this.errorMessages = 'حدث خطأ أثناء تحديث البريد الإلكتروني';
          }
          this.success = '';
          console.error('Error during login:', error);
        }
      } else {
        try {
          // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
          const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
            // country: this.dialCode,
            email: this.newEmail,
          },
            {
              headers: {
                'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json', // Specify the content type
              }
            });

          if (response.status === 200 || response.status === 201) {
            console.log(response.data.message);
            this.success = 'تم تحديث السجل بنجاح';
            this.errorMessages = '';
            localStorage.setItem('userData', JSON.stringify(response.data.user));
            this.$router.push({ name: 'Setting' }).then(() => {
              this.$router.go(0); // This will reload the page after navigating to 'Setting'
            });

          } else {
            console.log('Password Update Failed:', response.data.message);
            this.errorMessages = response.data.message;
          }
        } catch (error) {

          if (error.response.status === 400) {
            this.errorMessages = 'لقد تم أخذ البريد الإلكتروني بالفعل';
          } else {
            this.errorMessages = 'حدث خطأ أثناء تحديث البريد الإلكتروني';
          }
          this.success = '';
          console.error('Error during login:', error);
        }
      }

    },
    // crossGo() {
    //   // window.history.back();
    //   this.$router.push({ name: 'Setting', query: { redirect: '/setting' } });

    // }
  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}
</style>