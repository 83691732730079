<template>
  <div class="content signup">
    <div>
      <div class="row align-items-center justify-content-center pt-4 pb-4">
        <div class="col-md-6">
          <a href="/setting" class="btn btn-link"><img src=".././assets/images/backarrow.svg"></a>
        </div>
        <div class="col-md-6 text-end dir-rtl text-primarydark">
          <h1 class="text-primarydark fw-bold nanosans">
            تغيير كلمة المرور
          </h1>
        </div>
      </div>
      <div class="alert alert-success text-end" v-if="this.success != ''">{{ success }}</div>
      <div class="alert alert-danger text-end" v-if="this.errorMessages != ''">{{ errorMessages }}</div>
      <Form @submit="updatePass" ref="myForm" class="dir-rtl">
        <div class="form-group mb-3 position-relative text-end">
          <Field :type="inputType" class="form-control passwrd text-end" placeholder="أدخل كلمة المرور" v-model="oldpass"
            name="oldpass" @change='handleWritName()' :rules="isRequired" required />
          <button class="btn btn-link passshow passhow1" @click="togglePasswordVisibility" type="button">
            <img src=".././assets/images/eye-pass.svg" v-if="inputType === 'password'">
            <img src=".././assets/images/eyeicon2.png" v-else>
          </button>
          <ErrorMessage name="oldpass" class="error w-100 text-danger" />

        </div>
        <div class="form-group mb-3 position-relative text-end">
          <Field :type="inputType1" class="form-control passwrd text-end" placeholder="أدخل كلمة المرور الجديدة"
            v-model="newpass" name="newpass" :rules="passwordRules" />
          <button class="btn btn-link passshow passhow1" @click="togglePasswordVisibility1" type="button">
            <img src=".././assets/images/eye-pass.svg" v-if="inputType1 === 'password'">
            <img src=".././assets/images/eyeicon2.png" v-else>
          </button>
          <ErrorMessage name="newpass" class="error w-100 text-danger" />
        </div>
        <div class="form-group mb-3 position-relative text-end">
          <Field :type="inputType2" class="form-control passwrd text-end" placeholder="تأكيد كلمة المرور الجديدة"
            v-model="matchpass" name="matchpass" :rules="passwordRules" />
          <button class="btn btn-link passshow passhow1" @click="togglePasswordVisibility2" type="button">
            <img src=".././assets/images/eye-pass.svg" v-if="inputType2 === 'password'">
            <img src=".././assets/images/eyeicon2.png" v-else>
          </button>
          <ErrorMessage name="matchpass" class="error w-100 text-danger" />
          <div v-if="this.passsMatchMessage != ''" class="text-danger">{{ passsMatchMessage }}</div>
        </div>
        <button type="submit" class="btn btn-main btn-lg py-3 w-100 mt-5 dir-rtl nanosans" :disabled="isDisabled">
          إحفظ
        </button>
      </Form>
      {{ response }}

    </div>
  </div>
</template>
<script >
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      oldpass: '',
      newpass: '',
      matchpass: '',
      newEmail: '',
      passsMatchMessage: '',
      success: '',
      errorMessages: '',
      response: '',
      hideerror: false,
      inputType: 'password',
      inputType1: 'password',
      inputType2: 'password',

    };
  },
  watch: {
    matchpass(newVal) {
      if (newVal === this.newpass) {
        this.passsMatchMessage = '';
      } else {
        this.passsMatchMessage = 'كلمة السر غير متطابقة';
      }
      
    },
    newpass(val) {
      if (val === this.matchpass) {
        this.passsMatchMessage = '';
      } else {
        this.passsMatchMessage = 'كلمة السر غير متطابقة';
      }
    }
    
  },
  computed: {
    isDisabled() {
      return this.newpass !== this.matchpass;
    },
  },
  methods: {
    handleWritName(){
      this.hideerror = false;
      console.log('error hide kar diya', this.hideerror);
    },
    togglePasswordVisibility() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
    togglePasswordVisibility1() {
      this.inputType1 = this.inputType1 === 'password' ? 'text' : 'password';
    },
    togglePasswordVisibility2() {
      this.inputType2 = this.inputType2 === 'password' ? 'text' : 'password';
    },
    passwordRules(value) {
      // Password validation rules
      const minLength = 8;
      // const { newpass, matchpass } = this;
      
      if (!value && this.hideerror != true) {
        return 'كلمة المرور مطلوبة';
      } else if (value.length < minLength && this.hideerror != true) {
        return `كلمة المرور يجب أن تحتوي على ${minLength} أحرف على الأقل`;
      }

      return true;
    },

    isRequired(value) {
      if (!value && this.hideerror != true) {
        return 'هذه الخانة مطلوبه';
      }
      return true;
    },

    async updatePass() {
      try {
        // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
        const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
          // country: this.dialCode,
          old_password: this.oldpass,
          password: this.matchpass,
        },
          {
            headers: {
              'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json', // Specify the content type
            }
          });

        if (response.status === 200 || response.status === 201) {
          console.log(response.data.message);
          this.success = response.data.message;
          localStorage.setItem('userData', JSON.stringify(response.data.user));
          this.$router.push({ name: 'Setting', query: { redirect: '/setting' } });
          this.errorMessages = ''
          this.success = 'تم تحديث كلمة المرور بنجاح';
          this.oldpass = '';
          this.newpass = '';
          this.matchpass = '';
          this.hideerror = true;
          setTimeout(() => {
            this.$router.push({ name: 'Setting' }).then(() => {
              this.$router.go(0); // This will reload the page after navigating to 'Setting'
            });
          }, 1000);


        } else {
          console.log('Password Update Failed:', response.data.message);
          this.errorMessages = response.data.message;

        }
      } catch (error) {
        this.errorMessages = 'كلمة المرور الحالية غير صحيحة';
        console.error('Error during login:', error.response.data.message);
        if (error.response.data.message === 'Old password is incorrect') {
          this.errorMessages = 'كلمة سر قديمة ليست صحيحة'
        }

      }

    },
    crossGo() {
      window.history.back();
    }
  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}
</style>