<template>
    <!-- {{ achievedBadge }} -->
    <div class="modal fade" :class="modalBadge == true ? 'show' : ''" v-if="shouldShowModal" id="exampleModal"
        tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
        :style="modalBadge == true ? 'display: block' : ''">
        <div class="modal-dialog" role="document">
            <div class="modal-content modalc-badge">
                <div class="modal-header d-block">
                    <div class="text-end"><button type="button" class="btn-close" @click="dismissBadge"> </button></div>
                    <h3 class="text-center nanosans dir-rtl">لقد فتحت للتو شارة جديدة!</h3>
                </div>
                <div class="modal-body text-center pt-3 pb-5">

                    <img :src="achievedBadge[0].image" alt="">
                    <h4 class="nanosans mt-4">{{ achievedBadge[0].description }}</h4>

                    <router-link to="/allbadges" class="btn btn-primary bg-primarylight nanosans">
                        شاهد جميع الشارات
                    </router-link>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import badgesachivf from '../../assets/audio/badges.wav';

import axios from 'axios';
export default {
    data() {
        return {
            modalBadge: false,
            achievedBadge: [],

        }
    },
    computed: {
        shouldShowModal() {

            return this.achievedBadge.length > 0 && !!localStorage.getItem('hasSeenIntro');
        },
    },
    mounted() {
        this.fetchData();
        this.modalBadge = true;
    },
    watch: {
        shouldShowModal(newVal) {
            if (newVal) {
                this.modalBadge = true;
                var audiosspop = new Audio(badgesachivf);
                audiosspop.play();
            }
        }
    },
    methods: {

        dismissBadge() {
            this.modalBadge = false
        },
        async fetchData() {
            try {
                // const token = sessionStorage.getItem('token');
                // console.log(token);
                const response = await axios.get('https://backend.nadyalloughat.com/api/new-badges', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                    }
                });

                this.achievedBadge = response.data.new_badges;

                // sessionStorage.setItem('wordDay-english', this.profileGet.word_of_the_day.word_english);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }

        },
    }
}
</script>