const getCountry = () => {
  return fetch("https://ipinfo.io?token=c24ad3b523945e")
    .then(response => response.json())
    .then(data => {
      const country = data.country;
      
      if (!country) {
        console.warn("Unable to fetch the country");
      }

      return country;
    });
};

export default getCountry;
