
<template>
  <div class="main-page signup login vh-100">
    <div class="container-fluid ps-0 h-100">
      
      <div class="row align-items-md-center row-eq-height mt-md-0 h-100">
        <div class="col-md-5 h-100 login-bg vh-100">
          <h3 class="nanosans mt-3 mt-md-5 pt-2 mb-md-3">تعلم اللغة الإنجليزية الآن ! </h3>
          <div class="d-none d-md-inline">
            <img src="../assets/images/login-leftimg.svg" alt="" class="img-fluid">
          </div>
          <img src="../assets/images/login-bg-mobile.svg" alt="" class="d-inline-block d-md-none img-fluid">
        </div>
        <div class="col-md-7 text-end px-5 content-login h-md-100 position-relative">
          <HeaderMy class="d-none d-md-block text-end mb-5" />
          <h1 class="pb-4 text-primarydark fw-bold dir-rtl nanosans mt-4 mt-md-0" v-if="!successMessage">
            الرجاء ادخال رقمك للحصول على الرمز السري
          </h1>
          <h1 class="pb-2 text-primarydark fw-bold dir-rtl nanosans" v-if="successMessage">
            رمز التعريف الشخصي
          </h1>
          <p class="nanosans" v-if="successMessage">أدخل الرمز السري الذي تلقيته عبر الرسائل القصيرة</p>

          <div class="alert alert-danger" v-if="errors !== ''">{{ errors }}</div>
          <!-- {{ dialCode + arabicNumber }} -->
          <div v-if="!successMessage">
            <Form @submit="register">
              <div class="input-group mb-3">
                <div class="input-group-text code-phone">
                  <vue-country-code @onSelect="onSelect" :preferredCountries="['sa', 'ae', 'eg']" defaultCountry=""
                    v-model="myContryCode"></vue-country-code>
                  <!-- +961 <img src=".././assets/images/flaglebanon.svg"> -->
                </div>
                <Field type="text" class="form-control" id="currentphone" placeholder="رقم الهاتف المحمول"
                  v-model="arabicNumber" name="phone" :rules="minRequired" @input="convertToEnglish"
                  :maxlength="this.coutryCode" @change="samelenght" />

                <ErrorMessage name="email" class="error w-100 text-danger" />
              </div>
              <div class="form-group mb-3 forgetpass text-end nanosans">
                <!-- <a href="#">هل نسيت كلمة الس؟</a> -->
                <router-link to="/login">
                  هل لديك حساب؟<strong> تسجيل الدخول</strong>
                </router-link>
              </div>
              <div class="input-groupb">
                <button type="submit" class="btn btn-main btn-lg py-3 w-100 mt-4 nanosans position-relative login-btn"
                  :disabled="loadingDiv || disablebtn">
                  <div class="spinner" v-if="loadingDiv"></div>
                  اشتراك
                </button>
                <div class="input-pera text-center nanosans mt-4">
                  <h6 class="text-primarydark fw-semibold">سعر الخدمة ٢.٢٥ درهم في اليوم بعد يوم واحد مجانا ( بما في ذلك
                    ضريبة
                    القيمة المضافة ٥٪)</h6>
                  <h6><router-link to="/login" class="text-primarydark fw-semibold">
                      خروج
                    </router-link></h6>
                  <p class="mb-0">
                    عند النقر على اشتراك، فإنك تكون قد وافقت على بنود الشروط والاحكام التالية: - سعر الخدمة ٢.٢٥ درهم في
                    اليوم
                    بعد يوم واحد مجانا ( بما في ذلك ضريبة القيمة المضافة ٥٪) - سيتم خصم قيمة الاشتراك تلقائيا بعد الفترة
                    ...- يمكنك إيقاف الخدمة في أي وقت بإرسال
                    يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت صحي للوصول
                    السريع إلى المحتوى
                  </p>
                </div>
              </div>
            </Form>
          </div>
          <otpopup v-if="successMessage" :digitCount="4" :succesmessage="successMessage" />

        </div>

      </div> <!-- row end -->

    </div>
  </div>
</template>

<script>
import VueCountryCode from ".././components/VueCountryCode.vue";
import otpopup from "@/components/otpPop.vue";
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';
import HeaderMy from '.././components/common/HeaderMy.vue'

export default {
  name: 'signup-form',
  components: {
    VueCountryCode,
    Form,
    Field,
    ErrorMessage,
    HeaderMy,
    otpopup
  },
  data() {
    return {
      myContryCode: '',
      arabicNumber: '',
      dialCode: '',
      name: '',
      avatar: null,
      errors: '',
      successMessage: false,
      emailRequired: '',
      isValidEmail: true,
      loadingDiv: false,
      disablebtn: true,
      coutryCode: '',
    }
  },
  created() {
    const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn) {
      this.$router.push({ name: 'HomePage', query: { redirect: '/homepage' } });
    }
    console.log(axios);
  },
  watch: {
    email: 'validateEmail',
  },
  methods: {

    convertToEnglish() {

      const numericRegex = /^[0-9]*$/;
      if (!numericRegex.test(this.arabicNumber)) {
        // If it contains non-numeric characters, clear the field
        this.arabicNumber = this.arabicNumber.replace(/[^0-9]/g, '');
        setTimeout(() => {
          document.getElementById("currentphone").value = this.arabicNumber;
          // this.disablebtn = true
        });

      } else {
        // Convert Arabic numerals to English numerals
        const numerals = {
          '٠': '0',
          '١': '1',
          '٢': '2',
          '٣': '3',
          '٤': '4',
          '٥': '5',
          '٦': '6',
          '٧': '7',
          '٨': '8',
          '٩': '9',
        };

        let convertedNumber = '';

        for (let i = 0; i < this.arabicNumber.length; i++) {
          const char = this.arabicNumber[i];
          convertedNumber += numerals[char] || char;
        }

        this.arabicNumber = convertedNumber;
      }
      if (this.arabicNumber.length > 15) {
        this.arabicNumber = this.arabicNumber.slice(0, 15);


      }

    },
    minRequired(value) {
      if (!value) {
        return 'كلمة المرور مطلوبة';
      }
      if (!/^\d{5,}$/.test(value)) {
        return 'أدخل رقم هاتف صالح';
      }
      return true;
    },
    validateEmail() {
      // For example, using a simple regex:
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isValidEmail = emailRegex.test(this.email);
      if (this.email == 0) {
        this.isValidEmail = true
      }
    },
    isRequired(value) {
      if (!value) {
        return 'هذه الخانة مطلوبه';
      }
      return true;
    },
    async onSelect({ dialCode, iso2 }) {
      this.disablebtn = true;
      this.dialCode = dialCode;
      this.arabicNumber = '';
      const coutryCode = iso2;
      console.log(dialCode);
      try {
        const response = await axios.get('https://backend.nadyalloughat.com/api/check-country-digits/' + coutryCode, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            mode: 'no-cors',
          }
        });
        this.coutryCode = response.data.phone_digits_allowed;
        // console.log('verify:', this.coutryCode);

      } catch (error) {
        console.error('Error fetching data:', error);

      } finally {
        this.loadingDiv = false; 
      }
    },
    samelenght() {
      if (this.arabicNumber.length === this.coutryCode) {
        this.disablebtn = false;
      } else {
        this.disablebtn = true;
      }
    },

    async register() {
      this.loadingDiv = true;
      console.log(this.dialCode);
      try {
        const response = await axios.post('https://backend.nadyalloughat.com/api/register-with-number', {
          phone: this.dialCode + this.arabicNumber,
        },
          {
            headers: {
              'Access-Control-Allow-Origin': '*', 
              'Content-Type': 'application/json',
              mode: 'no-cors'
            }
          });
        if (response.status === 200 || response.status === 201) {
            this.successMessage = true;
            sessionStorage.setItem('registerNumber', this.dialCode + this.arabicNumber);
            sessionStorage.setItem('subservice_id', response.data.data.subservice_id)
            sessionStorage.setItem('transaction_id', response.data.data.transaction_id)
            console.log(response)
            this.errors = '';
          // this.$router.push({ name: 'HomePage', query: { redirect: '/homepage' } });
        }

        // if (response.status === 400 || response.status === 401) {
        //   // console.error('Login failed:', response.errors);
        //   this.errors = 'Phone number already taken';
        // }

      } catch (error) {
        
        console.error('Error during login:', error.response.data);
        this.errors = 'رقم الهاتف مأخوذ بالفعل';
      } finally {
        this.loadingDiv = false;
      }
    }
  }
}

</script>


<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}

@media screen and (max-width: 600px) {
  body {
    height: auto !important;
  }
}
</style>
