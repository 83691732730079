<template>
  <div class="home-page lessons">
    <div class="container">
      <header class="pb-md-2 pt-md-2">
        <HeaderNav />
      </header>

      <div class="content">
        <div class="row align-items-center justify-content-center mt-md-5">
          <div class="col-md-6 d-none d-md-block">
            <img src=".././assets/images/tree-element.svg">
          </div>
          <div class="col-md-6 text-end dir-rtl text-primarydark mt-5">
            <h1 class="text-white fw-bold nanosans">
              <button class="btn btn-link btn-arrow pe-0 d-inline-block d-md-none d-lg-none" @click="crossGo"><img
                  src=".././assets/images/arrow-left2.svg"></button>
              {{ cateName }}
            </h1>
          </div>
          <div class="col-md-6 d-block d-md-none">
            <img src=".././assets/images/tree-element.svg">
          </div>
        </div>
        <div class="row loadinglesson-m" v-if="loadingDiv">
          <div class="col-12 col-md-8">
            <div></div>
          </div>
          <div class="col-12 col-md-8">
            <div></div>
          </div>
          <div class="col-12 col-md-8">
            <div></div>
          </div>
          <div class="col-12 col-md-8">
            <div></div>
          </div>
          <div class="col-12 col-md-8">
            <div></div>
          </div>

        </div>
        <div class="row dir-rtl pb-5" v-else>
          <div class="col-md-12">
            <div class="lesson-list nanosans" v-for="(value, index) in lesson" :key="index">
              <div class="row">
                <div class="col-10">
                  <h3 class="w-100 d-block text-gold fw-bold">{{ toArabicNumber(value.name) }}</h3>
                </div>
                <div class="col-2 text-start"><img src=".././assets/images/lockicon.svg" v-if="value.isLock != '0'"></div>
              </div>

              <ul class="lesson-list nanosans mt-0">
                <li v-for="(item, i) in value.category_lessons" :key="i">
                  <!-- {{ lessonKnown }} {{ i }} -->
                  <router-link :to="{ name: 'Lessonstart', params: { id: item.id } }" v-if="value.isLock == '0'"
                    :class="item.isCompleted != 0 ? 'completedles' : ''" @click="getlesson(i + 1)">
                    {{ item.lesson_title }}

                    <span class="check" v-if="item.isCompleted == '1'"><img
                        src=".././assets/images/checkcomplete.svg" /></span>
                  </router-link>

                  <a v-if="value.isLock != '0'" class="disabled">
                    {{ item.lesson_title }}
                  </a>
                </li>
                <!-- <li>
                <a href="#">
                  <div class="w-80">
                    <img src=".././assets/images/car-ico.svg">
                    مواصلات
                  </div>
                  <div class="w-20 text-start">
                    1/2
                    <span class="progress-verti">
                      <span style="height: 30%;"></span>
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="w-80">
                    <img src=".././assets/images/apple.svg">
                    الفاكهة
                  </div>
                  <div class="w-20 text-start">
                    1/2
                    <span class="progress-verti">
                      <span style="height: 30%;"></span>
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="w-80">
                    <img src=".././assets/images/apple.svg">
                    الفاكهة
                  </div>
                  <div class="w-20 text-start">
                    1/2
                    <span class="progress-verti">
                      <span style="height: 30%;"></span>
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="w-80">
                    <img src=".././assets/images/apple.svg">
                    الفاكهة
                  </div>
                  <div class="w-20 text-start">
                    1/2
                    <span class="progress-verti">
                      <span style="height: 30%;"></span>
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="w-80">
                    <img src=".././assets/images/apple.svg">
                    الفاكهة
                  </div>
                  <div class="w-20 text-start">
                    1/2
                    <span class="progress-verti">
                      <span style="height: 30%;"></span>
                    </span>
                  </div>
                </a>
              </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import HeaderNav from '.././components/common/HeaderWhite.vue'
import axios from 'axios';

export default {
  name: 'HomeMain',
  components: {
    HeaderNav
  },
  data() {
    return {
      lesson: [],
      lessonKnown: 'الدرس',
      loadingDiv: true,
      cateName: `${localStorage.getItem('categoryname')}`
    };
  },
  mounted() {
    this.fetchData();

  },
 
  methods: {
    toArabicNumber(text) {
      const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      return text
        .toString()
        .split('')
        .map(char => {
          // Convert only numeric characters
          if (/\d/.test(char)) {
            return arabicDigits[char];
          }
          // Keep non-numeric characters (like % and .)
          return char;
        })
        .join('');
    },
    getlesson(lessonNumber) {
      localStorage.setItem('lessonNumber', lessonNumber);
      localStorage.setItem('currentPath', this.$route.fullPath);
    },
    async fetchData() {
      const lessonId = this.$route.params.id;
      try {
        const response = await axios.get('https://backend.nadyalloughat.com/api/category-lessons/' + lessonId, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            mode: 'no-cors',
            // 'ngrok-skip-browser-warning': '20349'
          }
        });

        this.lesson = response.data.data;

        // console.log(this.lesson)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
      }

    },
    crossGo() {
      // window.history.back();
      this.$router.push({name: 'Category'})
    },

  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}

@media screen and (max-width: 600px) {
  body {
    height: auto !important;
  }
}
</style>