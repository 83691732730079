import Velocity from 'velocity-animate';

const walkCatDirective = {
  mounted(el) {
    // Select specific paths using their IDs or classes
    const eyePath = el.querySelector('#cat-eye'); // Replace 'cat-eye' with the actual ID or class of the eye path
    const eyePath2 = el.querySelector('#cat-eye2'); // Replace 'cat-eye' with the actual ID or class of the eye path
    const tailPath = el.querySelector('#cat-tail'); // Replace 'cat-tail' with the actual ID or class of the tail path

    // Set initial position for each path
    Velocity(eyePath, { translateX: 0 }, { duration: 0 }); // Adjust the initial position as needed
    Velocity(eyePath2, { translateX: 0 }, { duration: 0 }); // Adjust the initial position as needed
    Velocity(tailPath, { rotateX: '15deg', rotateY: '5deg' }, { duration: 0, loop: true }); // Adjust the initial rotation as needed

    // Trigger animations for eyes
    Velocity(eyePath, { translateX: '-=11px' }, { duration: 1000, loop: true }); // Adjust the animation properties as needed
    Velocity(eyePath2, { translateX: '-=11px' }, { duration: 1000, loop: true }); // Adjust the animation properties as needed

    // Function to shake the tail
    const shakeTail = () => {
      Velocity(tailPath, { rotateX: '-=15deg', rotateY: '+=5deg' }, { duration: 1500 });
    };

    // Initial call to start the tail shaking
    shakeTail();

    // Set an interval to pause and resume the animations every 3 seconds
    const intervalId = setInterval(() => {
      Velocity(eyePath, 'pause');
      Velocity(eyePath2, 'pause');
      setTimeout(() => {
        Velocity(eyePath, 'resume');
        Velocity(eyePath2, 'resume');
      }, 2000);
    }, 3000); // The interval should be longer than the timeout duration

    // Stop the interval when the component is destroyed
    el.addEventListener('hook:beforeDestroy', () => {
      clearInterval(intervalId);
    });
  },
};

export default walkCatDirective;
