<template>
    <div class="home-page setting-page">
        <div class="container">
            <header>
                <HeaderNav />
            </header>
            <div class="content mb-5 d-inline-block w-100" v-if="!editPage">

                <div class="row dir-rtl profile-bg justify-content-center align-items-center">

                    <div class="col-md-7 pe-5 ps-xm-5">
                        <div class="row align-items-center justify-content-center pt-4 pb-4">
                            <div class="col-12 text-end">
                                <h1 class="text-primarydark fw-bold nanosans"> <button class="btn btn-link btn-arrow pe-0"
                                        @click="crossGo"><img src=".././assets/images/backarrow.svg"></button>

                                    إعدادات</h1>
                            </div>

                        </div>
                        <div class="text-end dir-rtl text-primarydark">

                            <h4 class="mb-4">معلومات شخصية</h4>
                        </div>
                        <ul class="info-list nanosans">
                            <li>
                                <div class="info-label position-relative" :class="this.email === null ? 'muted' : ''">
                                    <span class="warning-notify notificate" v-if="this.email === null"></span>
                                    البريد الإلكتروني
                                </div>
                                <div class="info-name">
                                    {{ maskEmail(email) }}
                                </div>
                                <button @click="editEmail">
                                    <img src=".././assets/images/send-arrow.svg">
                                </button>
                            </li>
                            <!-- <li>
                                <div class="info-label">
                                    جنس
                                </div>
                                <div class="info-name">
                                    {{ gender }}
                                </div>
                                <button @click="genderpage">
                                    <img src=".././assets/images/send-arrow.svg">
                                </button>
                            </li> -->
                            <li>
                                <div class="info-label">
                                    رقم الهاتف المحمول
                                </div>
                                <div class="info-name dir-ltr">
                                    {{ phone }}
                                </div>

                            </li>
                            <li>
                                <div class="info-label">
                                    كلمة المرور
                                </div>
                                <div class="info-name dir-ltr">
                                    **************
                                </div>
                                <button @click="editPass">
                                    <img src=".././assets/images/send-arrow.svg">
                                </button>
                            </li>
                            <!-- <li>
                                <div class="info-label">
                                    كلمة المرور
                                </div>
                                <div class="info-name">
                                    **************
                                </div>
                                <router-link to="/">
                                    <img src=".././assets/images/send-arrow.svg">
                                </router-link>
                            </li> -->
                        </ul>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-4 cta-setting">
                        <div class="text-center">
                            <img src="../assets/images/cat-straight.svg" alt="">
                        </div>
                        <div class="text-center acc-links">
                            <h3 class="nanosans fw-bold pb-3"><router-link to="/policy">سياسة
                                    الخصوصية</router-link></h3>
                            <h3 class="nanosans fw-bold pb-3"><router-link to="/terms">الأحكام والشروط</router-link></h3>
                            <h3 class="nanosans fw-bold pb-3"><router-link to="/unsubscribe">إلغاء الاشتراك</router-link></h3>
                            <h3 class="nanosans fw-bold pb-3"><router-link to="/delete-account">حذف الحساب</router-link></h3>
                        </div>
                        <div class="text-center">
                            <button type="button" @click="popups" class="btn btn-link nanosans fw-bold">تسجيل خروج</button>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="editPage">
                <EmailChange v-if="editemailPage"></EmailChange>
                <PasswordChange v-if="editePass"></PasswordChange>
                <!-- <GenderChange v-if="genderedit"></GenderChange> -->
            </div>

        </div>
    </div>
    <div class="modal fade " :class="modal === true ? 'show' : ''" id="exampleModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true"
        :style="modal === true ? 'display: flex; padding-right: 17px;' : ''">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body text-center pt-5 pb-5">
                    <h3 class="nanosans fw-bold">هل أنت متأكد أنك تريد تسجيل الخروج؟</h3>
                </div>
                <div class="modal-footer text-center d-inline-block">
                    <button type="button" class="btn btn-secondary" @click="dismiss">لا</button>
                    <button type="button" class="btn btn-primary bg-primarydark" @click="logout">نعم</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script >
import HeaderNav from '.././components/common/HeaderNav.vue'
import EmailChange from '.././components/EmailChange.vue'
import PasswordChange from '.././components/PasswordChange.vue'
// import GenderChange from '.././components/GenderChange.vue'
// import { computed } from "vue";
// import { useRoute } from "vue-router";
import axios from 'axios';


export default {
    name: 'HomeMain',
    components: {
        HeaderNav,
        EmailChange,
        PasswordChange,
        // GenderChange
    },
    // setup() {
    //     const route = useRoute();
    //     const type = computed(() => route.params.type);
    //     return {
    //         type,
    //     };
    // },
    data() {
        return {
            name: '',
            imageUrl: '',
            email: '',
            phone: '',
            editPage: false,
            editePass: false,
            editemailPage: false,
            genderedit: false,
            gender: '',
            modal: false,

        };
    },

    mounted() {
        const genderMapping = {
            male: 'ذكر',
            female: 'أنثى',
        };

        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.name = dataget.name
            this.imageUrl = dataget.avatar
            this.email = dataget.email
            this.phone = dataget.phone

            this.gender = genderMapping[dataget.gender.toLowerCase()] || dataget.gender;
        }
    },
    methods: {
        maskEmail(email) {
            if (email && email.length > 0) {
                const [username] = email.split('@');
                const maskedUsername = '...';
                return `${maskedUsername}@${username}`;
            }
        },
        dismiss() {
            this.modal = false
        },
        popups() {
            this.modal = true
        },
        isRequired(value) {
            if (!value) {
                return 'this field is required';
            }

            return true;
        },
        editEmail() {
            this.editPage = true;
            this.editemailPage = true
        },
        editPass() {
            this.editPage = true;
            this.editePass = true;
        },
        genderpage() {
            this.editPage = true;
            this.genderedit = true;
        },
        crossGo() {
            window.history.back();
        },
        async logout() {

            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/logout', null, {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                    }
                });
                console.log(response.data.message);
                if (response.status === 200 || response.status === 201) {
                    localStorage.clear(); 
                    this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                    // sessionStorage.clear(); 

                }
                if (response.status === 401) {
                    localStorage.clear(); 
                    this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                    // sessionStorage.clear(); 
                }

            } catch (error) {
                console.log(error);
                localStorage.clear(); 
                // sessionStorage.clear();
                this.$router.push({ name: 'Login', query: { redirect: '/login' } });
            }

        },
    }
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}
</style>