<template>
  <div>
    <!-- Arabic Options -->
    <ul class="choos-trans pair-tab mb-5">
      <li v-for="(items, i) in quizoptions" :key="i" dir="auto">
        <div class="box-shaper" :class="{
          'check-green': isMatchedPair(items.pair_id),
          'check-red': wrongAnswerArabic === items.pair_id
        }">
          <input type="radio" :id="'arabic-' + items.id + i" :name="'arabic-word-group'" :value="items.pair_id"
            @change="selectOption(i, 'arabic', items.pair_id, questionid, items.audio_file)"
            :disabled="isDisabled(items.pair_id)" v-model="words" />
          <label :for="'arabic-' + items.id + i">
            {{ items.option }}
          </label>
        </div>
      </li>
    </ul>

    <!-- English Options -->
    <ul class="choos-trans pair-tab english-value">
      <li v-for="(items, i) in quizoptionsecond" :key="i" dir="auto">
        <div class="box-shaper" :class="{
          'check-green': isMatchedPair(items.pair_id),
          'check-red': wrongAnswerEnglish === items.pair_id
        }">
          <input type="radio" :id="'english-' + items.id + i" :name="'english-word-group'" :value="items.pair_id"
            @change="selectOption(i, 'english', items.pair_id, questionid, items.audio_file)"
            :disabled="isDisabled(items.pair_id)" v-model="wording" />
          <label :for="'english-' + items.id + i">
            {{ items.option }}
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: ['quizoptions', 'questionid', 'allmatched', 'quizoptionsecond'],
  data() {
    return {
      words: null, // For Arabic selection
      wording: null, // For English selection
      matchedPairs: [], // Array to store matched pair_ids
      wrongAnswerArabic: null, // Store the wrong pair_id for Arabic for temporary red highlighting
      wrongAnswerEnglish: null,
      currentAudioo: null,
    };
  },
  watch: {
    questionid(newQuestionId) {
      this.matchedPairs = [];
      console.log(newQuestionId)
    }
  },
  methods: {
    // Method to select and match based on pair_id
    selectOption(i, source, pair_id, valueID, voicsound) {
      if (source === 'arabic') {
        this.words = pair_id;
      } else if (source === 'english') {
        this.wording = pair_id;
      }

      // Check if both Arabic and English options are selected
      if (this.words !== null && this.wording !== null) {
        if (this.words == this.wording) {
          // If pair_ids match, store the matched pair
          this.matchedPairs.push(this.words);
          this.wrongAnswerArabic = null; // Clear wrong answer since we have a match
          this.wrongAnswerEnglish = null; // Clear wrong answer since we have a match

          if (this.matchedPairs.length === this.quizoptions.length) {
            // console.log('compo se', valueID) 
            this.$emit('all-matched', true, 1, valueID);
            console.log('all matched now for', valueID);

            console.log(this.matchedPairs);
          }
        } else {
          // If pair_ids don't match, show error state briefly
          this.wrongAnswerArabic = this.words; // Temporarily set the wrong Arabic selection
          this.wrongAnswerEnglish = this.wording; // Temporarily set the wrong English selection

          this.$emit('checBtn', false); // Emit event for incorrect match
          setTimeout(() => {
            this.wrongAnswerArabic = null; // Clear the wrong answer for Arabic after 1 second
            this.wrongAnswerEnglish = null; // Clear the wrong answer for English after 1 second
          }, 1000);
        }

        // Reset the selections after checking
        setTimeout(() => {
          this.words = null;
          this.wording = null;
        }, 500);
      }
      if (this.currentAudioo) {
        this.currentAudioo.pause();
        this.currentAudioo.currentTime = 0;
      }
      if (voicsound) {
        this.currentAudioo = new Audio(voicsound);
        this.currentAudioo.play();

      }


    },

    // Check if the pair is already matched
    isMatchedPair(pair_id) {
      return this.matchedPairs.includes(pair_id);
    },

    // Disable matched options
    isDisabled(pair_id) {
      return this.isMatchedPair(pair_id);
    }
  }
};
</script>
