<template>
    <div class="home-page profile-main pb-5 pb-md-0" v-if="!editImagePage">
        <div class="container">
            <header class="mb-md-2 mt-md-2">
                <HeaderNav />
            </header>

        </div>
        <div class="content blue-bg">
            <div class="container">
                <div class="row pt-4">
                    <div class="col-md-6 text-start dir-rtl text-primarydark position-relative">
                        <div class="text-end text-md-start" v-if="!loadingDiv">
                            <router-link to="/setting" class="setting position-relative" :data-intro-step6="dataIntro">
                                <!-- {{ email }} -->
                                <span class="warning-notify" v-if="this.email === null"></span>
                                <img src=".././assets/images/setting-icon2.svg">
                                <div class="tooltip">
                                    إعدادات
                                </div>
                            </router-link>
                        </div>
                        <div class="element-tree">
                            <img src="../assets/images/tree-element.svg" alt="">
                        </div>
                    </div>
                    <div class="col-md-6 loadingprofile" v-if="loadingDiv">
                        <div class="proy-pic"></div>
                        <div class="wategae"></div>
                        <div class="wategae"></div>
                        <div class="wategae"></div>
                    </div>
                    <div class="col-md-6" v-else>

                        <div class="row align-items-center justify-content-center dir-rtl">
                            <div class="col-4 col-md-3 text-end dir-rtl text-white">
                                <div class="profile-image" v-if="imageUrl != null">
                                    <img :src="this.imageUrl">
                                    <!-- <img :src="this.imageUrl"> -->
                                </div>
                                <div class="profile-image" v-if="imageUrl == null">
                                    <img src="../assets/images/placeholder-pic.jpg">
                                    <!-- <img :src="this.imageUrl"> -->
                                </div>
                            </div>
                            <div class="col-8 col-md-9">
                                <h1 class="nanosans text-white fw-bold">
                                    {{ name }}
                                    <button class="btn btn-link" @click="editImage">
                                        <img src=".././assets/images/pencil-edit.svg">
                                    </button>
                                </h1>
                            </div>
                        </div>



                        <div class="row pt-4">
                            <div class="col-12">
                                <h2 class="dir-rtl prohead text-white nanosans">
                                    <img src=".././assets/images/uk-flag.png"> <span>بطلاقة</span> {{
                                        toArabicNumber(progresstext) }}
                                </h2>
                                <div class="progress progress-secondry dir-rtl">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0"
                                        aria-valuemax="100" :style="{ width: progress }"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row dir-rtl pb-3 pt-5">
                            <div class="col-md-12">
                                <div class="lesson-dros nanosans">
                                    <router-link to="/completed">
                                        <div class="w-50 fw-bold" v-if="totalCompletedLessons > 2">
                                            لقد أكملت {{ toArabicNumber(totalCompletedLessons) }} دروس
                                        </div>
                                        <div class="w-80 fw-bold" v-if="totalCompletedLessons === 1">
                                            لقد أكملت درسًا واحدًا
                                        </div>
                                        <div class="w-80 fw-bold" v-if="totalCompletedLessons === 2">
                                            لقد أكملت درسين
                                        </div>
                                        <div class="w-80 fw-bold" v-if="totalCompletedLessons === 0">
                                            لم تكمل أي درس بعد
                                        </div>
                                        <div class="text-end">
                                            <img src=".././assets/images/dars-book.svg" width="40">
                                        </div>
                                        <div class="w-20 text-start">
                                            <img src=".././assets/images/arrow-left.svg">
                                        </div>
                                    </router-link>
                                </div>
                                <router-link to="/categories"
                                    class="btn btn-main btn-lg py-3 w-100 nanosans mt-5 text-center hover-white">
                                    ابدأ الدرس
                                </router-link>

                                <div class="badges-area badges-areap">
                                    <router-link to="/allbadges"
                                        class="btn btn-link text-secondary fw-bold w-100 nanosans mt-3 text-end">
                                        عرض المزيد
                                    </router-link>
                                    <ul v-if="achievedBadges.length > 0">
                                        <li v-for="badges in achievedBadges" :key="badges.id">
                                            <img :src="badges.image" alt="">
                                        </li>
                                    </ul>
                                    <ul v-else>
                                        <li class="text-white">ابدأ الدرس للبدء في الحصول على الشارات</li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>

                    <!-- <div class="col-9 text-end dir-rtl text-primarydark">
                        
                        <h1 class="text-primarydark fw-bold nanosans mt-md-4">حساب تعريفي</h1>
                    </div> -->
                </div>




                <!-- <div class="row dir-rtl pb-5 d-none d-lg-block">
                    <div class="col-12">
                        <button class="btn btn-main btn-lg py-3 w-100 nanosans" data-toggle="modal"
                            data-target="#exampleModal" @click="popups">تسجيل خروج</button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <EditImage v-if="editImagePage"></EditImage>

    <!-- Modal -->
    <!-- <div class="modal fade " :class="modal === true ? 'show' : '' " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true" :style="modal === true ? 'display: block; padding-right: 17px;' : ''">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
      
                <div class="modal-body text-center pt-5 pb-5">
                    <h3 class="nanosans fw-bold">هل أنت متأكد أنك تريد تسجيل الخروج؟</h3>
                </div>
                <div class="modal-footer text-center d-inline-block">
                    <button type="button" class="btn btn-secondary" @click="dismiss">لا</button>
                    <button type="button" class="btn btn-primary bg-primarydark" @click="logout">نعم</button>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="modal fade" :class="modalBadge == true ? 'show' : ''" v-if="shouldShowModal" id="exampleModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true"
        :style="modalBadge == true ? 'display: block;' : ''">
        <div class="modal-dialog" role="document">
            <div class="modal-content modalc-badge" v-for="badge in unDismissedBadges" :key="badge.id">
                <div class="modal-header d-block">
                    <div class="text-end"><button type="button" class="btn-close" @click="dismissBadge(badge)"> </button></div>
                    <h3 class="text-center nanosans">لقد فتحت للتو شارة جديدة!</h3>
                </div>
                <div class="modal-body text-center pt-3 pb-5">
                    
                    <img :src="badge.image" alt="">
                    <h4 class="nanosans mt-4">{{ badge.description }}</h4>

                    <router-link to="/allbadges" class="btn btn-primary bg-primarylight nanosans">
                        شاهد جميع الشارات
                    </router-link>

                </div>

            </div>
        </div>
    </div> -->
</template>
<script>

import HeaderNav from '.././components/common/HeaderNew.vue'
import EditImage from '.././components/EditImage.vue'

import axios from 'axios';
import introJs from 'intro.js';


export default {
    name: 'HomeMain',
    components: {
        HeaderNav,
        EditImage
    },
    data() {
        return {
            progress: '',
            name: '',
            imageUrl: '',
            editImagePage: false,
            profileGet: [],
            categories: [],
            modal: false,
            email: '',
            // modalBadge: true,
            achievedBadges: [],
            loadingDiv: true,
            progresstext: '',
            dataIntro: 'قم بإدارة حسابك، وراجع معلومات ملفك وعدّلها حسب رغبتك',
            seenTutorial: 1,
        }
    },
    mounted() {
        this.fetchDataProfile();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.name = dataget.name
            this.imageUrl = dataget.avatar
            this.email = dataget.email
        }


        // Run Intro.js on first visit



    },
    computed: {

        totalCompletedLessons() {
            return this.categories.reduce((total, category) => {
                return total + category.user_completed_lessons;
            }, 0);
        },
        // shouldShowModal() {
        //     return this.unDismissedBadges.length > 0;
        // },
        // unDismissedBadges(){
        //     const dismissedBadges = this.getDismissedBadges();
        //     return this.achievedBadges.filter(badge => !dismissedBadges.includes(badge.id));
        // }
    },
    methods: {
        toArabicNumber(text) {
            const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
            return text
                .toString()
                .split('')
                .map(char => {
                    // Convert only numeric characters
                    if (/\d/.test(char)) {
                        return arabicDigits[char];
                    }
                    // Keep non-numeric characters (like % and .)
                    return char;
                })
                .join('');
        },
        startIntro() {
            const intro = introJs();
            intro.setOptions({
                steps: [
                    {
                        element: '[data-intro-step6]',
                        intro: this.dataIntro,
                        disableInteraction: true,
                    },

                ],
                showProgress: true,
                exitOnOverlayClick: false,
                exitOnEsc: false,
                showBullets: false,
                nextLabel: 'التالي', // Customize the Next button text
                prevLabel: 'السابق', // Customize the Back button text
                // skipLabel: 'تخطي', // Customize the Skip button text
                doneLabel: 'انتهاء', // Customize the Done button text
            });
            intro.start();
        },
        dismiss() {
            this.modal = false,
                this.modalBadge = false
        },
        // popups(){
        //     this.modal = true
        // },
        editImage() {
            this.editImagePage = true
        },
        async fetchUpdateProfile() {
            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
                    // country: this.dialCode,
                    has_seen_tutorial_setting_page: this.seenTutorial,
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json', // Specify the content type
                        }
                    });
                if (response.status === 200 || response.status === 201) {
                    // Login successful, handle the response (e.g., store user data)
                    // this.successMessage = true;
                    localStorage.setItem('userData', JSON.stringify(response.data.user));

                } else {

                    this.errorMessage = response.errors;
                }
            } catch (error) {
                console.error('Error during login:', error);
            } finally {
                this.loadingDiv = false;
            }
        },

        async fetchDataProfile() {
            try {
                // const token = sessionStorage.getItem('token');
                // console.log(token);
                const response = await axios.get('https://backend.nadyalloughat.com/api/word-of-the-day', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                        // 'ngrok-skip-browser-warning': '20349'
                    }
                });

                this.profileGet = response.data;
                this.progress = response.data.fluency_percentage + '%';
                this.progresstext = '%' + response.data.fluency_percentage;
                this.categories = response.data.categories;
                this.achievedBadges = response.data.achieved_badges;
                setTimeout(() => {
                    // const hasSeenIntro = localStorage.getItem('hasSeenIntro1');
                    const userhasSeen = JSON.parse(localStorage.getItem('userData'));
                    const SeenHas = userhasSeen.has_seen_tutorial_setting_page;
                    // let isRouteChanged = false;

                    // this.$watch('$route', () => {
                    //     isRouteChanged = true;
                    // });

                    if (this.$route.path === '/profile' && SeenHas !== 1) {
                        this.startIntro();
                        this.fetchUpdateProfile();
                        // localStorage.setItem('hasSeenIntro1', 'true'); // Mark as seen
                    }
                }, 1000);

                // sessionStorage.setItem('wordDay-english', this.profileGet.word_of_the_day.word_english);
                // if (response.data.status === 401) {
                //     sessionStorage.removeItem('token');
                // }
            } catch (error) {
                console.error('Error fetching data:', error.response.status);
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                }
            } finally {
                this.loadingDiv = false;
            }

        },
        // getDismissedBadges(){
        //     return JSON.parse(localStorage.getItem('dismissedBadges')) || [];

        // },
        // dismissBadge(badge){
        //     const dismissedBadges = this.getDismissedBadges();
        //     dismissedBadges.push(badge.id);
        //     localStorage.setItem('dismissedBadges', JSON.stringify(dismissedBadges));
        //     this.modalBadge = false

        // }
        // logout() {
        //     sessionStorage.removeItem('token');
        //     this.$router.push({ name: 'Login', query: { redirect: '/login' } });
        // }
    }
}
</script>
<style>
body {
    min-height: 98.8vh;
    height: auto !important;
}

#app {
    height: 100% !important;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>