<template>
    <nav class="navbar navbar-expand-md navbar-light bg-light dir-rtl nanosans d-none d-md-flex">
        <div class="logo d-none d-md-block">
            <RouterLink to="/homepage">
                <img src="../../assets/images/logo-nal.svg" width="85">
            </RouterLink>
        </div>
        <div class="collapse navbar-collapse me-5 pe-5" id="navbarSupportedContent">
            <ul class="navbar-nav w-100 d-block p-0 text-start">
                <li class="nav-item">
                    <router-link to="/homepage" class="nav-link" :data-intro-step3="dataIntroStep3">الصفحة الرئيسية</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/categories" class="nav-link" :data-intro-step1="dataIntroStep1">الفئات</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/profile" class="nav-link" :data-intro-step2="dataIntroStep2">
                        الحساب التعريفي</router-link>
                </li>
            </ul>
        </div>
    </nav>
    <nav class="navbar navbar-expand navbar-light bg-light mobile-nav d-block d-md-none">
        <div class="collapse navbar-collapse w-100">
            <ul class="navbar-nav mr-auto p-0 justify-content-around w-100">
                <li class="nav-item">
                    <router-link to="/homepage" class="nav-link" :data-intro-steps3="dataIntroSteps3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                            <path
                                d="M15.6049 5.70977L10.402 1.56177C9.94536 1.19768 9.38337 1 8.80488 1C8.22638 1 7.6644 1.19768 7.2078 1.56177L2.0039 5.70977C1.69116 5.95905 1.43814 6.2783 1.26415 6.64312C1.09016 7.00794 0.999813 7.40869 1 7.81477V15.0148C1 15.5452 1.20557 16.0539 1.5715 16.429C1.93742 16.8041 2.43372 17.0148 2.95122 17.0148H14.6585C15.176 17.0148 15.6723 16.8041 16.0383 16.429C16.4042 16.0539 16.6098 15.5452 16.6098 15.0148V7.81477C16.6098 6.99177 16.239 6.21477 15.6049 5.70977Z"
                                stroke="#757BE6" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/categories" class="nav-link" :data-intro-steps1="dataIntroSteps1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18" fill="none">
                            <path
                                d="M24.4665 5.57143L13.0379 1L1.60938 5.57143L13.0379 10.1429L24.4665 5.57143ZM24.4665 5.57143V12.4286"
                                stroke="#757BE6" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M6.18066 7.3999V13.5713C6.18066 14.4806 6.90311 15.3527 8.18907 15.9957C9.47504 16.6387 11.2192 16.9999 13.0378 16.9999C14.8564 16.9999 16.6006 16.6387 17.8865 15.9957C19.1725 15.3527 19.8949 14.4806 19.8949 13.5713V7.3999"
                                stroke="#757BE6" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/profile" class="nav-link" :data-intro-steps2="dataIntroSteps2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                            <path
                                d="M6.99557 8.11111C9.03109 8.11111 10.6812 6.51923 10.6812 4.55556C10.6812 2.59188 9.03109 1 6.99557 1C4.96005 1 3.30994 2.59188 3.30994 4.55556C3.30994 6.51923 4.96005 8.11111 6.99557 8.11111Z"
                                stroke="#757BE6" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M1.46704 16.9998V15.2221C1.46704 14.2791 1.85535 13.3747 2.54654 12.7079C3.23773 12.0411 4.17519 11.6665 5.15268 11.6665H8.83831C9.81581 11.6665 10.7533 12.0411 11.4445 12.7079C12.1356 13.3747 12.524 14.2791 12.524 15.2221V16.9998"
                                stroke="#757BE6" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>

    <!-- {{ globalTimer }} -->

    <WordDay :globalTimer="globalTimer" @reset-global-timer="resetGlobalTimer" />

    <AchivedBadge />
</template>

<script>
import { RouterLink } from 'vue-router';
import WordDay from './WordDayPopup.vue'
import AchivedBadge from './AchivedBadge.vue'
import '../../assets/resources/js/notify';

export default {
    props: {
        dataIntroStep1: {
            type: String,
            default: '',
        },
        dataIntroStep2: {
            type: String,
            default: '',
        },
        dataIntroStep3: {
            type: String,
            default: '',
        },
        dataIntroSteps1: {
            type: String,
            default: '',
        },
        dataIntroSteps2: {
            type: String,
            default: '',
        },
        dataIntroSteps3: {
            type: String,
            default: '',
        },
    },
    components: {
        WordDay,
        AchivedBadge,
        RouterLink
    },
    data() {
        return {
            globalTimer: parseInt(localStorage.getItem('globalTimer')) || 0,
        }
    },
    created() {
        this.startGlobalTimer();
    },
    
    methods: {
        startGlobalTimer() {
            setInterval(() => {
                if (this.globalTimer < 100) {
                    this.globalTimer++;
                    localStorage.setItem('globalTimer', this.globalTimer.toString()); // Update localStorage with the new global timer value
                } else {
                    this.globalTimer = 0;
                    localStorage.setItem('globalTimer', '0');

                }

            }, 1000);
        },
        resetGlobalTimer() {
            this.globalTimer = 0;
            localStorage.setItem('globalTimer', '0');
        },
    },
    //     methods: {
    //     setTokenInLocalStorage(token) {
    //       localStorage.setItem('userToken', token);
    //       setTimeout(() => {
    //         this.removeTokenFromLocalStorage();
    //       }, 3600000); // 1 hour in milliseconds
    //     },
    //     removeTokenFromLocalStorage() {
    //       localStorage.removeItem('userToken');
    //     },
    //     isTokenValid() {
    //       const token = localStorage.getItem('userToken');
    //       if (!token) {
    //         // Token is not present
    //         return false;
    //       }
    //       // You can add additional logic here to check the expiration time if needed
    //       return true;
    //     },
    //   },
};




</script>