
<template>
    <div class="main-page h-100">
        <div class="container h-100">
            <HeaderMy class="mainpage-logo" />
            <div class="content text-center mainpagec">
                <div class="row align-items-center d-flex">
                    <div class="col-12">
                      
                      <img src=".././assets/images/cat-language-app-loading.gif" class="d-block mx-auto mb-4 banner-billi">  
                      <img src=".././assets/images/mainpage-img.svg" class="d-block mx-auto mb-md-4 banner-img">
                        <h1 class="pt-4 nanosans">تحدث اللغة الانجليزية بثقة</h1>
                    </div>
                    <div class="col-lg-7 mx-auto">
                        <p class="lead mb-4 nanosans"> تعلم بطريقة سريعة وممتعة</p>
                        <div class="d-md-grid gap-2 d-md-flex justify-content-center">
                           <router-link to="/categories" class="btn btn-main btn-lg py-3 w-80 mt-4 nanosans btnmain-page">البدء</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script >
import HeaderMy from '.././components/common/HeaderMy.vue'

export default {
  name: 'HomeMain',
  components: {
    HeaderMy
  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}
@media screen and (max-width: 600px) {
  body{
    height: auto !important;
  }
}

</style>