<template>
  <div class="home-page lessons">
    <div class="container">
      <header class="pb-md-2 pt-md-2">
        <HeaderNav />
      </header>

      <div class="content">
        <div class="row align-items-end justify-content-center mt-md-5">
          <div class="col-md-6 d-none d-md-block">
            <img src="../.././assets/images/tree-element.svg">
          </div>
          <div class="col-md-6 text-end dir-rtl text-white mt-5 unsubs-detail">
            <h1 class="text-white fw-bold nanosans">
              <button class="btn btn-link btn-arrow pe-0 d-inline-block" @click="crossGo"><img
                  src="../.././assets/images/arrow-left2.svg"></button>
                  إدارة الاشتراك
            </h1>
            <h3 class="mt-4">اشتراكك البريميوم</h3>
            <h3 class="mt-4">اشتراكك</h3>
            <ul class="mt-4">
              <li> <strong>الحالة</strong>: <span v-if="pkgStatus === 1"> نشط</span></li>
              <li> <strong>{{pkgPrice}}</strong>: دولار / {{pkgType}}</li>
              <li> <strong>تاريخ الفاتورة القادمة</strong>: {{renewDate}}</li>
            </ul>
            <!-- <div>
              <router-link to="/" class="btn btn-main btn-lg py-3 w-100 nanosans mt-5 text-center yellow-btn">
                ترقية سنويا
              </router-link>
            </div> -->
            <div>
              <router-link to="/unsubscriboptions" class="btn btn-main btn-lg py-3 w-100 nanosans mt-5 text-center yellow-btn">
                إلغاء الاشتراك
              </router-link>
            </div>
          </div>
          <div class="col-md-6 d-block d-md-none">
            <img src="../.././assets/images/tree-element.svg">
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script >
import HeaderNav from '../.././components/common/HeaderWhite.vue'
import axios from 'axios';

export default {
  name: 'HomeMain',
  components: {
    HeaderNav
  },
  data() {
    return {
      lesson: [],
      lessonKnown: 'الدرس',
      loadingDiv: true,
      cateName: `${sessionStorage.getItem('categoryname')}`,
      pkgPrice: '',
      pkgStatus: '',
      pkgType: '',
      renewDate: '',
    };
  },
  mounted() {
    this.fetchData();

    const dataget = JSON.parse(localStorage.getItem('userData'));
    if (dataget) {
      this.subserviceid = dataget.subservice_id
      this.phone = dataget.phone
      this.pkgPrice = dataget.packagePrice
      this.pkgStatus = dataget.packageIsActive
      this.pkgType = dataget.packageType
      this.renewDate = dataget.renewalDate

    }

  },
 
  methods: {
    getlesson(lessonNumber) {
      sessionStorage.setItem('lessonNumber', lessonNumber);
      sessionStorage.setItem('currentPath', this.$route.fullPath);
    },
    async fetchData() {
      const lessonId = this.$route.params.id;
      try {
        const response = await axios.get('https://backend.nadyalloughat.com/api/category-lessons/' + lessonId, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            mode: 'no-cors',
            // 'ngrok-skip-browser-warning': '20349'
          }
        });

        this.lesson = response.data.data;

        // console.log(this.lesson)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
      }

    },
    crossGo() {
      window.history.back();
      // this.$router.push({name: 'Category'})
    },

  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}

@media screen and (max-width: 600px) {
  body {
    height: auto !important;
  }
}
</style>