<template>
    <div class="home-page">
        <div class="container">

            <div class="content">
                <div class="row align-items-center justify-content-center pt-4 pb-4">
                    <div class="col-md-4">
                        <button class="btn btn-link" @click="crossGo"><img
                                src="../.././assets/images/backarrow.svg"></button>
                    </div>
                    <div class="col-md-8 text-end dir-rtl text-primarydark">
                        <h1 class="text-primarydark fw-bold nanosans">سياسة الخصوصية</h1>
                    </div>
                </div>
                <TextLoader v-if="loadingDiv" />
                <div class="row dir-rtl pb-5" v-else>
                    <div class="col-md-12">
                        <div class="terms-text" v-html="formattedContent"></div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script >
import axios from 'axios';
// import datacontent from '../../assets/quiz-landing.json';
import TextLoader from '../.././components/loader/TextLoad.vue';


export default {
    name: 'PrivacyPolicy',
    components: {
        TextLoader
    },
    data() {
        return {
            name: '',
            editPage: false,
            editePass: false,
            editemailPage: false,
            genderedit: false,
            content: '',
            loadingDiv: true,

        };
    },

    mounted() {
        this.fetchData();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.name = dataget.name

        }

    },
    computed: {
        formattedContent(){
            return this.content.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>');
        }
    },
    methods: {
        async fetchData() {
            try {
                // const token = sessionStorage.getItem('token');
                // console.log(token);
                const response = await axios.get('https://backend.nadyalloughat.com/api/content/privacy_policy', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                    }
                });

                // console.log('ddfdf' ,response.data.content);

                this.content = response.data.content
                
                // this.categoriesin = response.data.content;
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }

        },

        crossGo() {
            window.history.back();
        },
        logout() {
            localStorage.removeItem('token');
            this.$router.push({ name: 'Login', query: { redirect: '/login' } });
        }
    }
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}
</style>