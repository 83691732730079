<template>
  <div class="home-page">
    <div class="container">
      <header class="mb-md-2 mt-md-2">
        <HeaderNav />
      </header>
      <div class="content pt-4 pt-md-0">
        <div class="row align-items-center justify-content-center">
          <div class="col-3">
            <img src=".././assets/images/cat-language-app-loading-cop.gif" class="img-responsive img-fluid">
          </div>
          <div class="col-9 text-end dir-rtl text-primarydark">
            <h1 class="text-primarydark fw-bold nanosans">الفئات</h1>
            <h5 class="dir-ltr">
              !استمتع بالتعلم
            </h5>
          </div>
        </div>


        <div class="row loadingcate-m category-list" v-if="loadingDiv">
          <div class="col-12 col-md-6">
            <div></div>
          </div>
          <div class="col-12 col-md-6">
            <div></div>
          </div>
          <div class="col-12 col-md-6">
            <div></div>
          </div>
          <div class="col-12 col-md-6">
            <div></div>
          </div>
          <div class="col-12 col-md-6">
            <div></div>
          </div>
          <div class="col-12 col-md-6">
            <div></div>
          </div>

        </div>
        <div class="row dir-rtl pb-5" v-else>
          <div class="col-md-12">
            <ul class="category-list nanosans">
              <li v-for="(value) in sortedCategories" :key="value.id" :data-intro-step7="dataIntroplaybtn">
                <router-link :to="{ name: 'Lessons', params: { id: value.id } }"
                  :class="value.lessons_completed === value.lessons.length && value.lessons != 0 ? 'completed' : ''"
                  @click="getName(value.name)">
                  <div class="w-80">
                    <img :src="value.image">
                    {{ value.name }}
                  </div>
                  <div class="w-20 text-start">
                    {{ toArabicNumber(value.lessons_completed) }}/{{ toArabicNumber(value.lessons.length) }}

                  </div>
                </router-link>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '.././components/common/HeaderNew.vue';
import { computed } from "vue";
import { useRoute } from "vue-router";
import axios from 'axios';
import introJs from 'intro.js';

export default {
  name: 'HomeMain',
  setup() {
    const route = useRoute();
    const id = computed(() => route.params.id);
    return {
      id,
    };
  },
  components: {
    HeaderNav
  },
  data() {
    return {
      categoriesin: [],
      loadingDiv: true,
      token: '',
      dataIntroplaybtn: 'انقر على أي فئة وخصص تجربتك التعليمية بطريقة تناسبك! كل فصل تُكمله يفتح لك المستوى الثاني ويأخذك خطوة نحو إتقان اللغة',
      seenTutorial: 1,
    };
  },
  mounted() {
    this.fetchData();


    // alert(SeenHas)
    // Run Intro.js on first visit

  },
  computed: {
    sortedCategories() {
      return this.categoriesin.slice().sort();
    }
  },
  methods: {
    toArabicNumber(text) {
      const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      return text
        .toString()
        .split('')
        .map(char => {
          // Convert only numeric characters
          if (/\d/.test(char)) {
            return arabicDigits[char];
          }
          // Keep non-numeric characters (like % and .)
          return char;
        })
        .join('');
    },
    startIntro() {
      const intro = introJs();
      intro.setOptions({
        steps: [
          {
            element: '[data-intro-step7]',
            intro: this.dataIntroplaybtn,
            disableInteraction: true,
          },
        ],
        showProgress: true,
        exitOnOverlayClick: false,
        exitOnEsc: false,
        showBullets: false,
        nextLabel: 'التالي', // Customize the Next button text
        prevLabel: 'السابق', // Customize the Back button text
        // skipLabel: 'تخطي', // Customize the Skip button text
        doneLabel: 'انتهاء', // Customize the Done button text

      });
      intro.start();
    },
    getName(catename) {
      localStorage.setItem('categoryname', catename);

    },
    async fetchUpdateProfile() {
      try {
        const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
          // country: this.dialCode,
          has_seen_tutorial_category_page: this.seenTutorial,
        },
          {
            headers: {
              'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json', // Specify the content type
            }
          });
        if (response.status === 200 || response.status === 201) {
          // Login successful, handle the response (e.g., store user data)
          // this.successMessage = true;
          localStorage.setItem('userData', JSON.stringify(response.data.user));

        } else {

          this.errorMessage = response.errors;
        }
      } catch (error) {
        console.error('Error during login:', error);
      } finally {
        this.loadingDiv = false;
      }
    },
    async fetchData() {
      try {
        // const token = sessionStorage.getItem('token');
        // console.log(token);
        const response = await axios.get('https://backend.nadyalloughat.com/api/active-categories-with-lessons', {
          headers: {
            'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            mode: 'no-cors',

          }
        });

        this.categoriesin = response.data.categories;
        this.catcomplete = response.data.categories.lessons_completed;

        setTimeout(() => {
          // this.startIntro();
          const userhasSeen = JSON.parse(localStorage.getItem('userData'));
          const SeenHas = userhasSeen.has_seen_tutorial_category_page;
          // let isRouteChanged = false;

          // this.$watch('$route', () => {
          //   isRouteChanged = true;
          // });

          const hasSeenIntro = localStorage.getItem('hasSeenIntro3');
          if (this.$route.path === '/categories' && !hasSeenIntro && SeenHas !== 1) {
            this.startIntro();
            this.fetchUpdateProfile();
            localStorage.setItem('hasSeenIntro3', 'true');
          }
        }, 1000);


      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          this.$router.push({ name: 'Login', query: { redirect: '/login' } });
        }
      } finally {
        this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
      }

    },

  }
}
</script>
<style>
body {
  height: 100vh;
}

#app {
  height: 100%;
}

@media screen and (max-width: 600px) {
  body {
    height: auto !important;
  }
}
</style>