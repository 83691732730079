<template>
  <div class="quiz-options row quiz-fillblank">
    <div class="col-md-12">
      <div class="sentence-fill border-0" :class="changeColor">
        <h1 dir="auto" class="text-primarydark fw-bold nanosans pt-3 mb-3" :class="changeColor">
          <span v-for="(part, index) in processedQuestion" :key="index">
            <!-- If it's a placeholder, replace it with the selected word -->
            <template v-if="part.isPlaceholder">
              <span class="">
                {{ selectedWords || "__" }}
              </span>
            </template>
            <!-- Otherwise, render the static text -->
            <template v-else>
              {{ part.text }}
            </template>
          </span>
        </h1>
      </div>
    </div>
    <ul class="choos-trans check-sentence">
      <li v-for="(word, i) in quizwords" :key="i">
        <div class="box-shaper">
          <input type="radio" :id="word.option + i" :name="word.option + i" v-model="sentenceMatch" :value="word.option"
            @change="selectWord(i, word.option, word.audio_file)" :disabled="!cisAllMatched" />
          <label :for="word.option + i" :disabled="!cisAllMatched">
            {{ word.option }}
          </label>
        </div>
      </li>
    </ul>
  </div>
  <div class="row mt-5" v-if="isAnsChecked">
    <div class="col-md-12 col-12 text-center">
      <button type="button"
        @click="checkAnswers(wordvalue.id, wordvalue.question_options[0].correct_answer, wordvalue.correct_sentence_question_audio_file)"
        class="btn btn-main btn-lg py-3 w-100" :disabled="!this.notdis && !isAllMatched">
        تحقق
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["quizwords", "wordvalue", "isAnsChecked"],
  data() {
    return {
      sentenceMatch: [],
      changeColor: "",
      notdis: false,
      cisAllMatched: true,
      correcAnswers: [],
      showAfterDelay: false,
      selectedWords: '', // Ensure this is an array
      processedQuestion: [],
      currentAudios: null,
    };
  },
  emits: ["correct-check", "notdis", "checBtn"],
  watch: {
    wordvalue: {
      immediate: true,
      handler(newVal) {
        if (newVal?.question) {
          this.processQuestion(newVal.question); // Process the question
        }
      },
    },
    'wordvalue.id': function (newId, oldId) {
      if (newId !== oldId) {
        this.resetQuestionState();
      }
    },
  },
  methods: {
    resetQuestionState() {
      this.selectedWords = ''; // Reset selected word
      this.sentenceMatch = []; // Reset matched sentence
      this.changeColor = ''; // Reset color class
      this.notdis = false; // Disable the button initially
      this.cisAllMatched = true; // Reset matching state
      this.processedQuestion = []; // Clear processed question
      if (this.wordvalue?.question) {
        this.processQuestion(this.wordvalue.question); // Re-process the question for the new ID
      }
    },

    processQuestion(question) {
      const parts = question.split("__"); // Split question by "__"
      this.processedQuestion = parts.reduce((acc, part, index) => {
        acc.push({ text: part, isPlaceholder: false }); // Add static text
        if (index < parts.length - 1) {
          acc.push({ text: "", isPlaceholder: true }); // Add placeholder
        }
        return acc;
      }, []);
    },

    selectWord(index, word, voice) {
      this.selectedWords = '';
      this.selectedWords = word;
      this.notdis = this.selectedWords !== undefined;

      if (this.currentAudios) {
        this.currentAudios.pause();
        this.currentAudios.currentTime = 0;
      }
      if (voice) {
        this.currentAudios = new Audio(voice);
        this.currentAudios.play();

      }
    },

    checkAnswers(questionId, correctAnswer, sound) {
      this.$emit('checBtn', false);
      this.$emit('notdis', true);
      const sentence = this.selectedWords.toLowerCase(); // Combine selected words
      const correctSentence = correctAnswer.toLowerCase();

      console.log(sentence, correctSentence);

      this.cisAllMatched = false;

      if (sentence === correctSentence) {

        setTimeout(() => {
          this.changeColor = "text-success";
        }, 100);

        this.$emit("correct-check", 1, questionId);
        // console.log(sound);

        if (sound) {
          const audio = new Audio(sound);

          setTimeout(() => {
            audio.play();
          }, 100);

        }

      } else {
        this.changeColor = "text-danger";
        this.$emit("correct-check", 0, questionId);
      }
    },
  },
};
</script>
