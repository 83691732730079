import Velocity from 'velocity-animate';

const sadCatDirective = {
  mounted(el) {

    const eyePath = el.querySelector('#cat-eye'); 
    const eyePath2 = el.querySelector('#cat-eye2'); 
    const mouthPath = el.querySelector('#cat-mouth'); 


    Velocity(eyePath, { translateY: '-5px' }, { duration: 500 }); 
    Velocity(eyePath2, { translateY: '-5px' }, { duration: 500 }); 
    Velocity(mouthPath, { opacity: 1}, { duration: 0 }); 

    Velocity(eyePath, { translateY: '-=4px' }, { duration: 1000, loop: true }); 
    Velocity(eyePath2, { translateY: '-=4px' }, { duration: 1000, loop: true }); 
    

    const makeSad = () => {
      Velocity(mouthPath, { opacity: 0 }, { duration: 1000, easing: 'easeInOutQuart' });
    };

    const makeHappy = () => {
      Velocity(mouthPath, { opacity: 1 }, { duration: 1000, easing: 'easeInOutQuart' });
    };

    makeSad();

    const intervalId = setInterval(() => {
      makeHappy();
      Velocity(eyePath, 'pause');
      Velocity(eyePath2, 'pause');
      setTimeout(() => {
        makeSad();
      }, 1000);
      setTimeout(() => {
        Velocity(eyePath, 'resume');
        Velocity(eyePath2, 'resume');
      }, 2000);
    }, 3000); 

    el.addEventListener('hook:beforeDestroy', () => {
      clearInterval(intervalId);
    });
  },
};

export default sadCatDirective;