<template>
    <div>
        <div class="chapter-slide">
            <div class="unitloader" v-if="unitload">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <carousel :settings="settings" :breakpoints="breakpoints" dir="rtl" v-else>
                <slide v-for="slide in unitData" :key="slide.id">
                    <div class="slide-c" v-if="slide.isLock === false">
                        <h3>{{ slide.name }}</h3>
                        <div class="completed-check dir-rtl"
                            v-if="slide.completedChapterCount === slide.chapterCount && slide.chapterCount > 0">
                            <img src=".././assets/images/clcheck-icon.svg" alt=""> مكتمل!
                        </div>
                        <div class="c-count mt-3 mb-3">
                            <span :class="slide.completedChapterCount === slide.chapterCount ? 'complete-counts' : ''">{{
                                toArabicNumber(slide.completedChapterCount) }}/{{ toArabicNumber(slide.chapterCount) }}</span>
                        </div>
                        <div class="border-hc mb-4" style="#EEB94061"
                            v-if="slide.completedChapterCount != slide.chapterCount || slide.chapterCount === 0"></div>
                        <router-link :to="{ name: 'RoadMap', params: { id: slide.id } }" class="btn btn-main"
                            @click="getPage(slide.name, slide.description, slide.id)">
                            <span v-if="slide.completedChapterCount != slide.chapterCount">ابدأ الدرس</span>
                            <span v-else-if="slide.completedChapterCount === slide.chapterCount">مراجعة</span>
                        </router-link>
                    </div>
                    <div class="slide-c slidec-lock" v-else>
                        <h3>{{ slide.name }}</h3>
                        <div class="completed-check dir-rtl"
                            v-if="slide.completedChapterCount === slide.chapterCount && slide.chapterCount > 0">
                            <img src=".././assets/images/clcheck-icon.svg" alt=""> مكتمل!
                        </div>
                        <div class="c-count mt-3 mb-3">
                            <span>{{ toArabicNumber(slide.completedChapterCount) }}/{{ toArabicNumber(slide.chapterCount) }}</span>
                        </div>
                        <div class="border-hc mb-4" style="#EEB94061"
                            v-if="slide.completedChapterCount != slide.chapterCount || slide.chapterCount === 0"></div>
                        <div class="btn btn-main">
                            <!-- <span v-if="slide.completedChapterCount === slide.chapterCount">مراجعة</span> -->
                            <span>يبدأ</span>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
    components: {
        Carousel,
        Slide,
    },
    setup() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: "center"
            },
            breakpoints: {
                1024: {
                    itemsToShow: 5.5,
                    snapAlign: "start"
                },
                700: {
                    itemsToShow: 1,
                    snapAlign: "center"
                },

            }
        };
    },
    data(){
        return {
            unitData: [],
            unitload: true,
        }
    },
    mounted(){
        this.fetchData();
    },
    methods: {
        toArabicNumber(num) {
            const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
            return num
                .toString()
                .split('')
                .map(digit => arabicDigits[digit])
                .join('');
        },
        async fetchData() {
            try {
                const responseUnite = await axios.get('https://backend.nadyalloughat.com/api/units', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                    }
                });
                this.unitData = responseUnite.data;
            } catch (error){
                console.error('Error fetching data:', error);
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                }
            } finally {
                this.unitload = false;
            }
        },
        getPage(name, discription, id) {
            sessionStorage.setItem('uname', name);
            localStorage.setItem('categoryname', name);
            sessionStorage.setItem('udiscription', discription);
            sessionStorage.setItem('uid', id);
            // localStorage.setItem('lessonNumber', id);
        },

        clickdisab(event) {
            if (this.youachiev.status == 'completed') {
                event.target.disabled = true
            }
        }
    },
}

</script>