<template>
    <div class="main-page signup login forgetpass vh-100">
        <div class="container-fluid ps-0 h-100">
            <div class="row align-items-md-center h-100">

                <div class="col-md-5 h-100 login-bg">
                    <h3 class="nanosans mt-3 mt-md-5 pt-2 mb-md-3">تعلم اللغة الإنجليزية الآن ! </h3>
                    <div class="d-none d-md-inline">
                        <img src="../assets/images/login-leftimg.svg" alt="" class="img-fluid">
                    </div>
                    <img src="../assets/images/login-bg-mobile.svg" alt="" class="d-inline-block d-md-none img-fluid">
                </div>
                <div class="col-md-7 text-end px-5 content-login h-md-100 position-relative">
                    <HeaderMy class="d-none d-md-block text-end mb-5" />
                    <div class="alert alert-success" v-if="this.successMessage != ''">{{successMessage}} <a
                            href="/login">تسجيل الدخول</a> </div>
                    <div class="alert alert-danger" v-if="this.errorMessage != ''">{{ errorMessage }}</div>

                    <div class="dir-rtl">
                        <h1 class="pb-4 mt-4 mt-md-0 mb-0 text-primarydark fw-bold nanosans">
                            إعادة تعيين كلمة المرور
                        </h1>
                        <p>
                            أدخل رقم هاتفك المحمول لتحصل على كلمة المرور الجديدة! ويمكنك دائمًا تعديله لاحقًا.
                        </p>
                    </div>
                    <!-- {{ coutryCode }} -->
                    <Form @submit="forgetpass">
                        <div class="input-group mb-3">
                            <div class="input-group-text code-phone">
                                <vue-country-code @onSelect="onSelect" :preferredCountries="['sa', 'ae', 'eg']"
                                    defaultCountry="" v-model="myContryCode"></vue-country-code>
                                <!-- +961 <img src=".././assets/images/flaglebanon.svg"> -->
                            </div>
                            <Field type="tel" class="form-control" id="currentphone" placeholder="رقم الهاتف المحمول"
                                v-model="arabicNumber" name="email" :rules="minRequired" @input="convertToEnglish"
                                :maxlength="this.coutryCode" :disabled="disablefieled" />

                            <ErrorMessage name="email" class="error w-100 text-danger" />
                        </div>

                        <div class="form-group mb-3 forgetpass text-end nanosans" v-if="resends">
                            <!-- <a href="#">هل نسيت كلمة الس؟</a> -->
                            <span v-if="disabled" @click="resend" class="text-primary" style="cursor: pointer;">
                                إعادة إرسال كلمة المرور
                            </span>
                            <span v-else>
                                إعادة إرسال كلمة المرور (معطلة لمدة {{ remainingTime }} ثانية)
                            </span>
                        </div>

                        <div class="input-groupb">
                            <button type="submit"
                                class="btn btn-main btn-lg py-3 w-100 mt-5 dir-rtl nanosans loadinginblue login-btn"
                                :disabled="loadingDiv || disablebtn">
                                <div class="spinner" v-if="loadingDiv"></div>
                                تابع
                            </button>
                            <router-link to="/login"
                                class="btn btn-signup btn-lg py-3 w-100 mt-4 dir-rtl nanosans position-relative login-btn">العودة
                                لتسجيل الدخول</router-link>
                        </div>
                    </Form>

                    <!-- <vue-tel-input v-model="phone"></vue-tel-input> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import VueCountryCode from ".././components/VueCountryCode.vue";
import { Field, Form, ErrorMessage } from 'vee-validate';
import HeaderMy from '.././components/common/HeaderMy.vue';
import axios from 'axios';

export default {
    name: 'login-form',
    components: {
        VueCountryCode,
        Form,
        Field,
        ErrorMessage,
        HeaderMy
    },

    data() {
        return {
            phonenumer: '',
            arabicNumber: '',
            errorMessage: '',
            successMessage: '',
            myContryCode: '',
            codeNow: '',
            dialCode: '',
            loadingDiv: false,
            disablebtn: true,
            coutryCode: '',
            disabled: true,
            resends:false,
            remainingTime: 0,
            countdownInterval: null,
            disablefieled: false,
        }
    },

    created() {
        // const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
        // if (isLoggedIn) {
        //     this.$router.push({ name: 'HomePage', query: { redirect: '/homepage' } });
        // }
    },
    beforeUnmount() {
        // Clear the interval to prevent memory leaks when the component is destroyed
        clearInterval(this.countdownInterval);
    },
    methods: {
        convertToEnglish() {

            const numericRegex = /^[0-9]*$/;
            if (!numericRegex.test(this.arabicNumber)) {
                // If it contains non-numeric characters, clear the field
                this.arabicNumber = this.arabicNumber.replace(/[^0-9]/g, '');
                setTimeout(() => {
                    document.getElementById("currentphone").value = this.arabicNumber;
                    // this.disablebtn = true
                });

            } else {
                // Convert Arabic numerals to English numerals
                const numerals = {
                    '٠': '0',
                    '١': '1',
                    '٢': '2',
                    '٣': '3',
                    '٤': '4',
                    '٥': '5',
                    '٦': '6',
                    '٧': '7',
                    '٨': '8',
                    '٩': '9',
                };

                let convertedNumber = '';

                for (let i = 0; i < this.arabicNumber.length; i++) {
                    const char = this.arabicNumber[i];
                    convertedNumber += numerals[char] || char;
                }

                this.arabicNumber = convertedNumber;
            }
            if (this.arabicNumber.length > 15) {
                this.arabicNumber = this.arabicNumber.slice(0, 15);
            }
            if (this.arabicNumber.length === this.coutryCode) {
                this.disablebtn = false;
            } else {
                this.disablebtn = true;
            }

        },

        isRequired(value) {
            if (!value) {
                return 'رقم الهاتف مطلوب';
            }
            return true;
        },
        minRequired(value) {
            if (!value) {
                return 'رقم الهاتف مطلوب';
            }
            if (!/^\d{5,}$/.test(value)) {
                return 'أدخل رقم هاتف صالح';
            }
            return true;
        },
        togglePasswordVisibility() {
            this.inputType = this.inputType === 'password' ? 'text' : 'password';
            // this.showHimage = this.showHimage === 'eye-pass.svg' ? 'eye-pass.svg' : 'eye-pass.svg';
        },
        async onSelect({ dialCode, iso2 }) {
            this.disablebtn = true;
            this.arabicNumber = '';
            this.disablefieled = true;
            this.dialCode = dialCode;
            const coutryCode = iso2;
            try {
                const response = await axios.get('https://backend.nadyalloughat.com/api/check-country-digits/' + coutryCode, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        mode: 'no-cors',
                    }
                });
                this.coutryCode = response.data.phone_digits_allowed;
                console.log('verify:', this.coutryCode);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
                this.disablefieled = false;
            }

            // console.log(dialCode);
        },

        async forgetpass() {
            this.loadingDiv = true;
            try {
                // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
                const response = await axios.post('https://backend.nadyalloughat.com/api/forget-password-by-number', {
                    // country: this.dialCode,
                    phone: this.dialCode + this.arabicNumber,
                });

                if (response.status === 200 || response.status === 201) {

                    this.successMessage = 'تم إرسال طلب كلمة المرور بنجاح';
                    // this.arabicNumber = '';
                    this.errorMessage = '';
                    this.disablebtn = true;
                    this.resends = true;
                }
                if (response.status === 500) {
                    this.errorMessage = 'فشل طلب إعادة تعيين كلمة المرور. الرجاء ارسال الرقم الصحيح';
                    // this.arabicNumber = '';
                    this.successMessage = '';
                }
                if (response.status === 400) {
                    this.errorMessage = 'المستخدم غير مشترك أو لم يتم العثور على المستخدم';
                    // this.arabicNumber = '';
                    this.successMessage = '';
                }

            } catch (error) {

                this.errorMessage = 'المستخدم غير مشترك أو لم يتم العثور على المستخدم';
                // this.arabicNumber = '';
                this.successMessage = '';
                

            } finally {
                this.loadingDiv = false;
            }
        },

        async resend() {
            this.disabled = false;
            this.remainingTime = 60;
            this.countdownInterval = setInterval(() => {
                // Decrease remaining time by 1 second
                this.remainingTime--;
                // If the remaining time reaches 0, clear the interval and enable the link
                if (this.remainingTime <= 0) {
                    clearInterval(this.countdownInterval);
                    this.disabled = true;
                }
            }, 1000); // Update every second
            try {
                // const myConstantIs = document.getElementsByClassName('country-code')[0].innerText;
                const response = await axios.post('https://backend.nadyalloughat.com/api/forget-password-by-number', {
                    // country: this.dialCode,
                    phone: this.dialCode + this.arabicNumber,
                });

                if (response.status === 200 || response.status === 201) {

                    this.successMessage = 'تم إرسال طلب كلمة المرور بنجاح';
                    // this.arabicNumber = '';
                    this.errorMessage = '';

                    setTimeout(() => {
                        this.disabled = true;
                    }, 60000);
                }
                if (response.status === 500) {
                    this.errorMessage = 'فشل طلب إعادة تعيين كلمة المرور. الرجاء ارسال الرقم الصحيح';
                    // this.arabicNumber = '';
                    this.successMessage = '';
                }
                if (response.status === 400) {
                    this.errorMessage = 'المستخدم غير مشترك أو لم يتم العثور على المستخدم';
                    // this.arabicNumber = '';
                    this.successMessage = '';
                }

            } catch (error) {

                this.errorMessage = 'المستخدم غير مشترك أو لم يتم العثور على المستخدم';
                // this.arabicNumber = '';
                this.successMessage = '';

            } finally {
                this.loadingDiv = false;
            }
        }


    }
}

</script>