import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router configuration
import VueCountryCode from 'vue-country-code';
import axios from 'axios';
import VueAxios from 'vue-axios';
// import en from 'vee-validate/dist/locale/en.json';
// import * as rules from '@vee-validate/rules';
// import { Field, Form, defineRule, ErrorMessage } from 'vee-validate';
// import * as rules from '@vee-validate/rules';
import WalkCatDirective from './directives/v-walk-cat';
import sadCatDirective from './directives/s-happy-cat';
// import { firebaseConfig } from '../src/firebaseapp/firebaseConfig'; // Import your Firebase configuration
// import { initializeApp } from 'firebase/app';

import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.js'; // Import Bootstrap js
import 'bootstrap'; // Import Bootstrap JS
import './assets/styles/global.scss';
import 'intro.js/introjs.css';

// import '../src/assets/resources/js/sockboot';
// Register VeeValidate rules
// Object.keys(rules).forEach((rule) => {
//     defineRule(rule, rules[rule]);
//   });

// localize('en', en);
// Enable promise rejection tracking
// const firebaseApp = initializeApp(firebaseConfig);

// Service Worker Registration

// Request FCM token  
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//         .then((registration) => {
//             console.log('Service Worker registered with scope:', registration.scope);
//         })
//         .catch((error) => {
//             console.error('Error registering Service Worker:', error);
//         });
// }


const app = createApp(App);

app.config.warnHandler = (msg, vm, trace) => {
    // `trace` is the stack trace of the unhandled promise rejection
    console.error(`Unhandled Promise Rejection: ${msg}`, vm, trace);
};

createApp(App).use(router).use(VueCountryCode).use(VueAxios, axios).directive('walk-cat', WalkCatDirective).directive('happy-cat', sadCatDirective).mount('#app')
