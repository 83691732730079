
<template>
    <div class="main-page signup vh-100">
        <div class="container-fluid ps-0 h-100">
            <!-- <HeaderMy class="d-none d-md-block text-end mb-5" /> -->
            <div class="row align-items-md-center row-eq-height h-100 mt-md-0">
                <div class="col-md-5 h-100 login-bg">
                    <h3 class="nanosans mt-3 mt-md-5 pt-2 mb-md-3">تعلم اللغة الإنجليزية الآن ! </h3>
                    <div class="d-none d-md-inline">
                        <img src="../assets/images/login-leftimg.svg" alt="" class="img-fluid">
                    </div>
                    <img src="../assets/images/login-bg-mobile.svg" alt="" class="d-inline-block d-md-none img-fluid">
                </div>
                <div class="col-md-7 text-end px-5 content-login h-md-100 position-relative">
                    <h1 class="pb-4 text-primarydark mt-4 mt-md-5 fw-bold dir-rtl nanosans pt-5"
                        v-if="this.nameHere === false">
                        ما اسمك؟
                    </h1>
                    <h1 class="pb-4 text-primarydark mt-5 fw-bold dir-rtl nanosans pt-md-5" v-if="this.nameHere === true">
                        أضف بريدك الإلكتروني
                    </h1>
                    <div class="alert alert-success" v-if="successMessage">Profile Updated</div>
                    <div class="alert alert-danger" v-if="error != ''">{{ error }}</div>

                    <Form @submit="registerName" v-if="this.nameHere === false">
                        <div class="form-group mb-3">
                            <Field type="text" id="name" name="name" class="form-control" placeholder="اسم" v-model="name"
                                :rules="isRequired" />
                            <ErrorMessage name="name" class="error w-100 text-danger" />
                        </div>

                        <div class="input-groupb">
                            <button type="submit"
                                class="btn btn-main btn-lg py-3 w-100 mt-5 nanosans position-relative login-btn"
                                :disabled="loadingDiv">
                                <div class="spinner" v-if="loadingDiv"></div>
                                أضف
                            </button>
                        </div>
                    </Form>
                    <Form @submit="registerEmail" v-if="this.nameHere === true">
                        <div class="form-group mb-3">
                            <Field type="email" id="email" name="email" class="form-control" placeholder="بريد إلكتروني"
                                v-model="email" @blur="validateEmail" :rules="isRequiredemail" />
                            <span class="error w-100 text-danger" v-if="!isValidEmail">
                                تنسيق البريد الإلكتروني غير صالح
                            </span>
                            <ErrorMessage name="email" class="error w-100 text-danger" />

                        </div>

                        <div class="input-groupb">
                            <button type="submit"
                                class="btn btn-main btn-lg py-3 w-100 mt-5 nanosans position-relative login-btn"
                                :disabled="loadingDiv">
                                <div class="spinner" v-if="loadingDiv"></div>
                                أضف
                            </button>
                            <a href="/homepage"
                                class="btn btn-link text-secondary fw-bold btn-lg py-3 w-100 mt-3 nanosans position-relative login-btn">
                                تخطى
                            </a>
                        </div>
                    </Form>

                </div>
                <!-- <div class="col-md-6 text-end" v-if="successMessage">
          <h1 class="pb-4 text-primarydark fw-bold dir-rtl">Thanks for Register</h1>
          <p class="text-primarydark"><router-link to="/login" class="btn btn-main">Login</router-link></p>
        </div> -->

            </div>
        </div>
    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';
// import HeaderMy from '.././components/common/HeaderMy.vue'

export default {
    name: 'signup-form',
    components: {
        Form,
        Field,
        ErrorMessage,
        // HeaderMy
    },
    data() {
        return {
            gender: '',
            email: '',
            name: '',
            avatar: null,
            error: '',
            successMessage: false,
            emailRequired: '',
            isValidEmail: true,
            loadingDiv: false,
            alreadyName: null,
            nameHere: false,
        }
    },
    created() {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
        if (isLoggedIn) {
            this.$router.push({ name: 'HomePage', query: { redirect: '/homepage' } });
        }
    },
    watch: {
        email: 'validateEmail',
    },
    mounted() {
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.alreadyName = dataget.name
        }
    },
    methods: {
        validateEmail() {
            // Implement your email format validation logic here
            // You can use a regular expression or any other method
            // For example, using a simple regex:
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.isValidEmail = emailRegex.test(this.email);
            if (this.email == 0) {
                this.isValidEmail = true
            }
        },
        isRequired(value) {
            const minLength = 1;
            const maxLength = 20;
            if (!value) {
                return 'مطلوب اسم';
            } else if (value.length < minLength) {
                return `يجب أن يكون الاسم أكثر من 5 أحرف`;
            } else if (value.length > maxLength) {
                return `يجب ألا يتجاوز طول حقل الاسم 20 حرفًا`;
            }

            return true;
        },
        isRequiredemail(value) {
            if (!value) {
                return 'هذه الخانة مطلوبه';
            }
            return true;
        },

        async registerName() {
            this.loadingDiv = true;
            console.log(this.dialCode);
            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
                    // country: this.dialCode,
                    name: this.name,
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json', // Specify the content type
                        }
                    });
                if (response.status === 200 || response.status === 201) {
                    this.nameHere = true;
                    localStorage.setItem('userData', JSON.stringify(response.data.user));
                    // this.$router.push({ name: 'HomePage', query: { redirect: '/homepage' } });
                } else {
                    // console.error('Login failed:', response.errors);
                    this.errorMessage = response.errors;
                }
            } catch (error) {
                console.error('Error during login:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }
        },
        async registerEmail() {
            this.loadingDiv = true;
            console.log(this.dialCode);
            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
                    // country: this.dialCode,
                    email: this.email,
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json', // Specify the content type
                        }
                    });
                if (response.status === 200 || response.status === 201) {
                    // Login successful, handle the response (e.g., store user data)
                    this.successMessage = true;
                    localStorage.setItem('userData', JSON.stringify(response.data.user));
                    this.$router.push({ name: 'HomePage', query: { redirect: '/homepage' } });
                } else {
                    // Login failed, display an error message
                    // console.error('Login failed:', response.errors);
                    this.errorMessage = response.errors;
                }
            } catch (error) {
                if (error.response.status === 400) {
                    this.error = 'لقد تم أخذ البريد الإلكتروني بالفعل';
                } else {
                    this.error = 'حدث خطأ أثناء تحديث البريد الإلكتروني';
                }
                console.error('Error during login:', error);
            } finally {
                this.loadingDiv = false;
            }
        }
    }
}

</script>


<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>
