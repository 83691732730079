<template>
    <div class="home-page profile-main">
        <div class="container">
            <header class="mb-md-2 mt-md-2">
                <HeaderNav />
            </header>
        </div>
        <!-- {{ getBadges }} -->
        <div class="content blue-bg">

            <div class="container pb-5 pb-md-0">
                <div class="row align-items-top justify-content-center">
                    <div class="col-md-6 position-relative">
                        <div class="text-start pt-4">
                            <router-link to="/notifications" class="setting position-relative">
                                <!-- {{ email }} -->
                                <span class="warning-notify" v-if="this.email === null"></span>
                                <img src=".././assets/images/notification-icon.svg">
                                <div class="tooltip">
                                    إشعار
                                </div>
                            </router-link>
                        </div>
                        <div class="d-block d-md-none text-end">

                            <h1 class="text-white fw-bold nanosans">شارات <button class="btn btn-link btn-arrow pe-0"
                                    @click="crossGo"><img src=".././assets/images/backarrow.svg"></button></h1>
                        </div>

                        <!-- <div class="wordofday" v-if="profileGet.word_of_the_day != null">
                            <div class="catarea"><img src="../assets/images/goldencat.svg" alt=""></div>
                            <div class="wordround">
                                <h2 class="dir-rtl prohead text-primarydark pt-5 pb-3 nanosans">
                                    كلمة اليوم
                                </h2>
                                <div class="row word-day text-center">
                                    <div class="col-md-6 secnd-blue poppins">
                                        {{ profileGet.word_of_the_day.word_english }}
                                    </div>
                                    <div class="col-md-6 nanosans">
                                        {{ profileGet.word_of_the_day.word_arabic }}
                                    </div>
                                </div>
                            </div>
                        </div> -->


                    </div>

                    <div class="col-md-6 dir-rtl">

                        <h1 class="text-white fw-bold nanosans d-none d-md-block mt-4"><button
                                class="btn btn-link btn-arrow pe-0" @click="crossGo"><img
                                    src=".././assets/images/backarrow.svg"></button>
                            شارات </h1>

                        <h5 class="text-white fw-bold nanosans mt-3 mt-md-5 mb-3">إتقان</h5>
                        <div class="winarea">

                            <div class="main-bdgeasgn">
                                <img src="../assets/images/main-badge1.svg" alt="">
                            </div>
                            <div class="catarea">
                                <svg width="221" height="165" viewBox="0 0 221 165" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" v-walk-cat>
                                    <path
                                        d="M219.892 151.127H17.4485C17.2428 151.127 17.0455 151.046 16.9 150.9C16.7545 150.755 16.6729 150.558 16.6729 150.352C16.6729 150.146 16.7545 149.949 16.9 149.803C17.0455 149.658 17.2428 149.576 17.4485 149.576H219.892C220.098 149.576 220.295 149.658 220.441 149.803C220.586 149.949 220.668 150.146 220.668 150.352C220.668 150.558 220.586 150.755 220.441 150.9C220.295 151.046 220.098 151.127 219.892 151.127Z"
                                        fill="#CACACA" />
                                    <path
                                        d="M121.53 20.8872L120.197 4.29489C120.071 3.94069 119.858 3.62369 119.578 3.37309C119.298 3.12249 118.959 2.94619 118.593 2.86029C118.227 2.77439 117.845 2.78179 117.482 2.88159C117.12 2.98149 116.788 3.17059 116.518 3.43179C105.821 6.05629 101.504 11.6413 100.268 18.6555C95.1345 17.5675 89.8572 17.33 84.6468 17.9524C88.4072 12.6 88.148 7.14479 84.2363 1.59639C84.1263 1.23339 83.9263 0.904086 83.6548 0.639186C83.3833 0.374186 83.0491 0.182286 82.6835 0.0812864C82.3178 -0.0198136 81.9327 -0.0267133 81.5637 0.0611867C81.1947 0.148987 80.8539 0.328888 80.5731 0.583888C73.3376 4.24079 68.2767 8.79809 67.4618 15.1136C58.3748 19.9868 51.0881 27.6431 46.67 36.9598C42.2519 46.2766 40.9347 56.764 42.9118 66.884C44.8889 77.004 50.0562 86.224 57.656 93.193C65.2558 100.162 74.8883 104.513 85.1412 105.607C95.3942 106.702 105.728 104.484 114.628 99.277C123.528 94.07 130.526 86.148 134.595 76.674C138.664 67.2 139.591 56.671 137.24 46.6313C134.889 36.5917 129.383 27.5694 121.53 20.8872Z"
                                        fill="#EEB940" />
                                    <path
                                        d="M127.554 88.219C166.658 83.203 188.656 146.988 125.571 150.542C121.7 154.788 91.362 158.339 65.6516 152.476C42.5742 153.401 16.3399 123.215 48.6907 82.043L127.554 88.219Z"
                                        fill="#EEB940" />
                                    <path d="M88.5156 121.134V153.402H103.602L115.572 120.545" fill="#EEB940" />
                                    <path
                                        d="M142.405 142.52C142.322 142.401 142.262 142.276 142.19 142.154C141.548 142.178 140.905 142.202 140.262 142.225L142.405 142.52Z"
                                        fill="#EEB940" />
                                    <path
                                        d="M201.848 123.877C197.032 116.179 185.248 114.04 178.034 119.554C174.779 122.043 172.585 125.64 169.804 128.649C165.126 133.678 158.886 136.98 152.096 138.02C147.37 138.729 142.37 138.344 138.005 140.29C133.772 142.178 130.579 147.565 132.883 151.464C149.131 150.865 165.378 150.266 181.626 149.667C186.3 149.495 191.141 149.282 195.32 147.182C203.434 143.106 206.664 131.574 201.848 123.877Z"
                                        fill="#EEB940" class="tail" id="cat-tail" />
                                    <path
                                        d="M86.5522 101.578C79.8786 101.639 73.2645 100.319 67.1254 97.7008C54.3079 92.1368 48.433 82.7478 48.1892 82.3508C48.109 82.2178 48.0844 82.0588 48.1211 81.9078C48.1577 81.7578 48.2524 81.6268 48.3846 81.5458C48.5168 81.4648 48.6758 81.4388 48.8269 81.4748C48.978 81.5098 49.1089 81.6038 49.1913 81.7348C53.8348 88.3298 60.2294 93.4948 67.6532 96.6478C79.1954 101.638 98.9065 104.139 127.258 87.7108C127.325 87.6718 127.399 87.6468 127.476 87.6358C127.552 87.6258 127.63 87.6308 127.705 87.6508C127.78 87.6708 127.85 87.7048 127.911 87.7518C127.973 87.7988 128.024 87.8568 128.063 87.9238C128.102 87.9908 128.127 88.0648 128.137 88.1418C128.147 88.2178 128.142 88.2958 128.122 88.3708C128.102 88.4458 128.068 88.5158 128.021 88.5768C127.974 88.6378 127.915 88.6898 127.848 88.7288C111.205 98.3718 97.4637 101.578 86.5522 101.578Z"
                                        fill="white" />
                                    <path
                                        d="M89.1035 71.7279C88.0119 71.7269 86.9654 71.2919 86.1936 70.5209C85.4217 69.7489 84.9875 68.7019 84.9863 67.6109C84.9871 67.4549 85.0494 67.3059 85.1596 67.1969C85.2698 67.0869 85.419 67.0249 85.5744 67.0249C85.7299 67.0249 85.8791 67.0869 85.9893 67.1969C86.0995 67.3059 86.1618 67.4549 86.1626 67.6109C86.1639 68.3899 86.4743 69.1359 87.0256 69.6869C87.577 70.2369 88.3244 70.5469 89.1035 70.5469C89.8827 70.5469 90.63 70.2369 91.1814 69.6869C91.7327 69.1359 92.0431 68.3899 92.0444 67.6109C92.0452 67.4549 92.1075 67.3059 92.2178 67.1969C92.328 67.0869 92.4771 67.0249 92.6326 67.0249C92.788 67.0249 92.9372 67.0869 93.0474 67.1969C93.1576 67.3059 93.2199 67.4549 93.2207 67.6109C93.2195 68.7019 92.7853 69.7489 92.0135 70.5209C91.2416 71.2919 90.1951 71.7269 89.1035 71.7279Z"
                                        fill="white" />
                                    <path
                                        d="M89.3968 62.0668C91.9955 62.0668 94.1022 61.2568 94.1022 60.2568C94.1022 59.2578 91.9955 58.4468 89.3968 58.4468C86.7981 58.4468 84.6914 59.2578 84.6914 60.2568C84.6914 61.2568 86.7981 62.0668 89.3968 62.0668Z"
                                        fill="white" />
                                    <path
                                        d="M57.7583 60.39C59.1048 58.778 60.7888 57.481 62.6914 56.592C64.594 55.702 66.6688 55.241 68.7691 55.241C70.8695 55.241 72.9442 55.702 74.8468 56.592C76.7494 57.481 78.4334 58.778 79.7799 60.39C82.0611 58.212 83.6453 55.407 84.3322 52.329C85.0191 49.251 84.7778 46.0382 83.6388 43.0973C82.4998 40.1563 80.5143 37.6192 77.9334 35.8066C75.3525 33.9941 72.292 32.9876 69.1391 32.9145C65.9862 32.8413 62.8823 33.7047 60.2201 35.3956C57.5578 37.0864 55.4568 39.5287 54.1826 42.4137C52.9084 45.2986 52.5183 48.4966 53.0617 51.603C53.605 54.71 55.0574 57.586 57.2351 59.867C57.4054 60.045 57.5798 60.22 57.7583 60.39Z"
                                        fill="white" />
                                    <path
                                        d="M100.812 60.39C102.158 58.778 103.842 57.481 105.745 56.592C107.648 55.702 109.722 55.241 111.823 55.241C113.923 55.241 115.998 55.702 117.9 56.592C119.803 57.481 121.487 58.778 122.833 60.39C125.115 58.212 126.699 55.407 127.386 52.329C128.073 49.251 127.831 46.0382 126.692 43.0973C125.553 40.1563 123.568 37.6192 120.987 35.8067C118.406 33.9941 115.346 32.9876 112.193 32.9145C109.04 32.8413 105.936 33.7047 103.274 35.3955C100.611 37.0864 98.5102 39.5287 97.2364 42.4136C95.9622 45.2985 95.5721 48.4965 96.1154 51.603C96.6587 54.71 98.1112 57.586 100.289 59.867C100.459 60.045 100.633 60.22 100.812 60.39Z"
                                        fill="white" id="eye-circle" />
                                    <path
                                        d="M79.4852 51.97C82.5146 51.97 84.9704 49.515 84.9704 46.4852C84.9704 43.4558 82.5146 41 79.4852 41C76.4558 41 74 43.4558 74 46.4852C74 49.515 76.4558 51.97 79.4852 51.97Z"
                                        fill="#3B3786" id="cat-eye" />
                                    <path
                                        d="M122.485 50.97C125.515 50.97 127.97 48.5146 127.97 45.4852C127.97 42.4558 125.515 40 122.485 40C119.456 40 117 42.4558 117 45.4852C117 48.5146 119.456 50.97 122.485 50.97Z"
                                        fill="#3B3786" id="cat-eye2" />
                                    <path
                                        d="M112.337 71.9958C114.991 71.9958 117.143 69.8438 117.143 67.1888C117.143 64.5348 114.991 62.3828 112.337 62.3828C109.682 62.3828 107.53 64.5348 107.53 67.1888C107.53 69.8438 109.682 71.9958 112.337 71.9958Z"
                                        fill="#3B3786" />
                                    <path
                                        d="M68.9002 71.9958C71.5548 71.9958 73.7068 69.8438 73.7068 67.1888C73.7068 64.5348 71.5548 62.3828 68.9002 62.3828C66.2456 62.3828 64.0938 64.5348 64.0938 67.1888C64.0938 69.8438 66.2456 71.9958 68.9002 71.9958Z"
                                        fill="#3B3786" />
                                    <path d="M37.2075 110.441L11 138.045L17.8335 149.812L42.5742 135.029" fill="#EEB940" />
                                    <path
                                        d="M83.0383 161.5C83.3713 159.25 78.1708 156.617 71.4226 155.618C64.6745 154.619 58.9341 155.633 58.6011 157.882C58.2681 160.132 63.4686 162.765 70.2168 163.764C76.9649 164.763 82.7053 163.749 83.0383 161.5Z"
                                        fill="#EEB940" />
                                    <path
                                        d="M18.3795 149.379C19.9874 147.771 17.3805 142.557 12.5568 137.734C7.73317 132.91 2.51937 130.303 0.911467 131.911C-0.696423 133.519 1.91048 138.733 6.73415 143.556C11.5578 148.38 16.7716 150.987 18.3795 149.379Z"
                                        fill="#EEB940" />
                                    <path d="M65.5566 120.826L69.6186 158.672L82.9869 161.448L87.7672 132.659"
                                        fill="#EEB940" />
                                    <path
                                        d="M124.542 160.722C131.324 159.988 136.623 157.56 136.379 155.3C136.134 153.039 130.438 151.801 123.656 152.535C116.874 153.269 111.574 155.696 111.819 157.957C112.063 160.217 117.759 161.455 124.542 160.722Z"
                                        fill="#EEB940" />
                                    <path d="M109.21 120.342L122.678 155.942L136.314 155.26L133.687 126.196"
                                        fill="#EEB940" />
                                </svg>

                            </div>

                        </div>

                    </div>


                </div>
                <div class="row loadingbadge-m" v-if="loadingDiv">
                    <div class="col-12">
                        <div></div>
                    </div>

                </div>
                <div class="row dir-rtl pb-5" v-else>
                    <div class="col-md-12" v-for="badges in getBadges" :key="badges.id">
                        <h5 class="text-white fw-bold nanosans mt-2 mt-md-5 mb-3">{{ badges.name }}</h5>
                        <div class="badges-area badges-all mt-3">
                            <ul>
                                <li v-for="badged in badges.badges" :key="badged.id">
                                    <a href="javascript:;" @click="openModal(badged)"
                                        :class="badged.achieved === false ? 'blackwhot' : ''">
                                        <img :src="badged.image" alt="">
                                        <span class="namshow nanosans"
                                            v-if="badged.name != null && badged.achieved === true">{{ badged.name }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        <h5 class="text-white fw-bold nanosans mt-2 mt-md-5 mb-3">متقن التعددية</h5>
                        <div class="badges-area mt-3">
                            <ul>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> 
                                    <span class="namshow nanosans">متسلق</span>
                                </a> </li>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> 
                                    <span class="namshow nanosans">متسلق</span>
                                </a> </li>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> 
                                    <span class="namshow nanosans">متسلق</span>
                                </a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h5 class="text-white fw-bold nanosans mt-2 mt-md-5 mb-3">محقق</h5>
                        <div class="badges-area mt-3">
                            <ul>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> 
                                    <span class="namshow nanosans">متسلق</span>
                                </a> </li>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> 
                                    <span class="namshow nanosans">متسلق</span>
                                </a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h5 class="text-white fw-bold nanosans mt-2 mt-md-5 mb-3">طالب جيد</h5>
                        <div class="badges-area mt-3">
                            <ul>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> 
                                    <span class="namshow nanosans">متسلق</span>
                                </a> </li>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> </a> </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h5 class="text-white fw-bold nanosans mt-2 mt-md-5 mb-3">موجهة نحو الهدف</h5>
                        <div class="badges-area mt-3">
                            <ul>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> </a> </li>
                                <li><a href="#" class="blackwhot"><img src="../assets/images/badge-p1.svg" alt=""> </a> </li>
                            </ul>
                        </div>
                    </div> -->
                </div>

            </div>

        </div>
    </div>
    <div class="modal" :class="{ 'show': modal, 'fade': modal }" tabindex="-1" role="dialog"
        :style="modal === true ? 'display: block; padding-right: 17px;' : ''">
        <div class="modal-dialog" role="document">
            <div class="modal-content modalc-badge">

                <div class="modal-body text-center pt-3 pb-5" v-if="selectedBadge">
                    <div class="text-end">
                        <button type="button" class="close" @click="dismissModal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <img :src="selectedBadge.image" alt="">
                    <h4 class="nanosans">{{ selectedBadge.description }}</h4>
                    <!-- Add other badge properties you want to display -->
                </div>
            </div>
        </div>
    </div>
</template>
<script >
import HeaderNav from '.././components/common/HeaderNew.vue'
import axios from 'axios';
// import { Carousel, Slide } from 'vue3-carousel';
import walkCatDirective from '@/directives/v-walk-cat';

export default {
    name: 'HomeMain',
    directives: {
        'v-walk-cat': walkCatDirective,
    },
    components: {
        HeaderNav,
    },
    data() {
        return {
            modal: false,
            getBadges: [],
            loadingDiv: true,
            selectedBadge: null,
        }
    },
    mounted() {
        this.fetchData();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.profileName = dataget.name
        }
    },
    computed: {

    },
    methods: {
        dismissModal() {
            this.modal = false;
        },
        openModal(badge) {
            // Set the selected badge and open the modal
            this.selectedBadge = badge;
            this.modal = true;
        },
        async fetchData() {
            try {
                // const token = sessionStorage.getItem('token');
                // console.log(token);
                const response = await axios.get('https://backend.nadyalloughat.com/api/badges', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',

                    }
                });

                this.getBadges = response.data;

                // sessionStorage.setItem('wordDay-english', this.profileGet.word_of_the_day.word_english);
                // console.log('hdjhdsj', this.categories);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }

        },

        clickdisab(event) {
            if (this.youachiev.status == 'completed') {
                event.target.disabled = true
            }
        },
        crossGo() {
            window.history.back();
        }
    }
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>