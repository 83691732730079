<template>
    <div class="home-page profile-main">
        <div class="container">
            <header class="mb-md-2 mt-md-2">
                <HeaderNav />
            </header>
        </div>
        <div class="content roadmap-bg minh-auto">
            <div class="heading-road dir-rtl text-end nanosans">
                <div class="container">
                    <h1>{{ unitName }}</h1>
                    <p class="w-80">{{ unitDisc }}</p>
                </div>
            </div>
        </div>
        <div class="content roadmap-bg minh-60" :key="currentChapterIndex">
            <div class="afterheading-road dir-rtl text-end nanosans">
                <div class="container" v-if="chapters.length > 0">
                    <h4>{{ getCurrentChapter().name }}</h4>
                </div>
            </div>

            <div class="rmap dir-rtl pt-md-5">
                <div class="container" v-if="loadingDiv">
                    <ul class="loadingless">
                        <li>
                            <div class="playbox"></div>
                        </li>
                    </ul>
                </div>
                <div class="container" v-else>
                    <ul class="nanosans pt-md-5" v-if="getCurrentChapter() && getCurrentChapter().lessons">

                        <!-- <li class="completed">
                            <router-link to="/">
                                <div class="playbox">
                                    <img src="../assets/images/completedq-bg.svg" alt="">
                                    <div class="bedge">
                                    <img src="../assets/images/brown-bedge.png" alt="">

                                    </div>
                                    <span>
                                        مكتمل
                                    </span>
                                </div>
                                الدرس 1
                            </router-link>
                        </li> -->
                        <!-- <li class="playing">
                            <router-link to="/">
                                <div class="playbox">
                                    <img src="../assets/images/play-btn.svg" alt="" class="unclick">
                                    <img src="../assets/images/play-btnclick.svg" alt="" class="click">
                                </div>
                                الدرس 1
                            </router-link>
                        </li> -->

                        <li class="" v-for="(lesson, i) in getCurrentChapter().lessons" :key="i"
                            :data-intro-step7="dataIntroplaybtn">
                            <div class="cat-pow"
                                :class="lesson.isLock === false ? (lesson.user_lesson_progress_completed_at !== null ? 'completed' : 'playing') : 'lock'">

                                <router-link :disabled="isLock == true" to="javascript:;" v-if="lesson.isLock === true">
                                    <div class="playbox">
                                        <img src="../assets/images/lockplay.svg" alt="" class="">
                                    </div>
                                    {{ lesson.lesson_title }}
                                </router-link>

                                <router-link :to="{ name: 'Lessonstart', params: { id: lesson.id } }"
                                    v-if="lesson.isLock === false && lesson.user_lesson_progress_completed_at === null"
                                    @click="getDate(i + 1)">
                                    <div class="playbox">
                                        <img src="../assets/images/play-btn.svg" alt="" class="unclick">
                                        <img src="../assets/images/play-btnclick.svg" alt="" class="click">
                                    </div>
                                    {{ lesson.lesson_title }}
                                </router-link>

                                <router-link :to="{ name: 'Lessonstart', params: { id: lesson.id } }"
                                    v-if="lesson.isLock === false && lesson.user_lesson_progress_completed_at !== null"
                                    @click="getDate(i + 1)">
                                    <div class="playbox">
                                        <img src="../assets/images/completedq-bg.svg" alt="">
                                        <!-- <div class="bedge">
                                            <img src="../assets/images/brown-bedge.png" alt="">

                                        </div> -->
                                        <span>
                                            مكتمل
                                        </span>
                                    </div>
                                    {{ lesson.lesson_title }}
                                </router-link>
                            </div>
                        </li>

                        <li
                            :class="getCurrentChapter().lessons && getCurrentChapter().lessons.every(lesson => lesson.user_lesson_progress_completed_at != null) ? 'playing play-quiz' : 'lock'">
                            <router-link
                                :to="{ name: 'QuizStart', params: { uid: unitid, cid: getCurrentChapter().id } }"
                                @click="getDate" v-if="getCurrentChapter().is_completed != true">
                                <div class="playbox">
                                    <img src="../assets/images/akhtiyar.svg" alt="" class="">
                                </div>
                                <div class="play-quizin">
                                    <img src="../assets/images/playquiz-btn.svg" alt="" class="">
                                </div>
                                اختبار
                            </router-link>
                            <router-link
                                :to="{ name: 'QuizStart', params: { uid: unitid, cid: getCurrentChapter().id } }"
                                @click="getDate" v-else>
                                <div class="play-quizin">
                                    <img src="../assets/images/quiz-comletedico.svg" alt="" class="">
                                </div>
                                اختبار
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="nav-arrow arrow-left" :class="currentChapterIndex === chapters.length - 1 ? 'd-none' : ''">
                <button @click="showNextChapter">
                    <img src="../assets/images/arrow-left2.svg" alt="">
                </button>
            </div>
            <div class="nav-arrow arrow-right" :class="currentChapterIndex === 0 ? 'd-none' : ''">
                <button @click="showPreviousChapter" :disabled="currentChapterIndex === 0">

                    <img src="../assets/images/arrow-right2.svg" alt="">
                </button>
            </div>

        </div>
    </div>
</template>
<script>
import HeaderNav from '.././components/common/HeaderNew.vue';
import axios from 'axios';
import introJs from 'intro.js';

import 'vue3-carousel/dist/carousel.css';
// import { useRoute } from 'vue-router';

export default {
    name: 'HomeMain',

    components: {
        HeaderNav,

    },
    setup() {
        return {
            settings: {
                itemsToShow: 1.5,
                snapAlign: "center"
            },
            breakpoints: {
                1024: {
                    itemsToShow: 5.5,
                    snapAlign: "start"
                },
                700: {
                    itemsToShow: 1.5,
                    snapAlign: "center"
                },

            }
        };
    },
    data() {
        return {
            progress: '',
            profileName: '',
            profileGet: [],
            categories: [],
            completelesson: '',
            totalesson: '',
            chapters: [],
            currentChapterIndex: 0,
            unitName: sessionStorage.getItem('uname'),
            unitDisc: sessionStorage.getItem('udiscription'),
            unitid: sessionStorage.getItem('uid'),
            loadingDiv: true,
            seenTutorial: 1,
            currentChapterIndexFromRoute: 0,
            disablebtns: true,
            dataIntroplaybtn: 'انطلق الآن، فقد حان وقت تعلم الإنجليزية! اضغط هنا للبدء، وأكمل كل فصل لفتح الفصل التالي ومواصلة تقدمك!',
        }
    },
    mounted() {
        this.fetchData();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.profileName = dataget.name
        }
        this.currentChapterIndexFromRoute = parseInt(this.$route.query.chid) || 0;

        // const userhasSeen = JSON.parse(localStorage.getItem('userData'));
        // const SeenHas = userhasSeen.has_seen_tutorial
        // alert(SeenHas)
        // Run Intro.js on first visit


    },
    computed: {
        currentChapter() {
            return this.chapters[this.currentChapterIndex];
        },
    },
    watch: {
        currentChapterIndexFromRoute(newIndex) {
            this.currentChapterIndex = newIndex;
        },
    },
    methods: {
        async fetchUpdateProfile() {
            try {
                const response = await axios.post('https://backend.nadyalloughat.com/api/profile/update', {
                    // country: this.dialCode,
                    has_seen_tutorial_unit_page: this.seenTutorial,
                },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json', // Specify the content type
                        }
                    });
                if (response.status === 200 || response.status === 201) {
                    // Login successful, handle the response (e.g., store user data)
                    // this.successMessage = true;
                    localStorage.setItem('userData', JSON.stringify(response.data.user));


                } else {

                    this.errorMessage = response.errors;
                }
            } catch (error) {
                console.error('Error during login:', error);
            } finally {
                this.loadingDiv = false;
            }
        },
        startIntro() {
            const intro = introJs();
            intro.setOptions({
                steps: [
                    {
                        element: '[data-intro-step7]',
                        intro: this.dataIntroplaybtn,
                        disableInteraction: true,
                    },
                ],
                showProgress: true,
                exitOnOverlayClick: false,
                exitOnEsc: false,
                showBullets: false,
                nextLabel: 'التالي', // Customize the Next button text
                prevLabel: 'السابق', // Customize the Back button text
                // skipLabel: 'تخطي', // Customize the Skip button text
                doneLabel: 'انتهاء', // Customize the Done button text

            });
            intro.start();
        },
        showNextChapter() {
            const nextChapterIndex = this.currentChapterIndexFromRoute + 1;

            if (nextChapterIndex < this.chapters.length && this.chapters[nextChapterIndex].isLock === false) {
                this.updateQuery({ chid: nextChapterIndex });
                this.currentChapterIndexFromRoute = nextChapterIndex;
                this.disablebtns = false;
            }
        },

        showPreviousChapter() {
            const previousChapterIndex = this.currentChapterIndexFromRoute - 1;

            if (this.currentChapterIndexFromRoute > 0) {
                this.updateQuery({ chid: previousChapterIndex });
                this.currentChapterIndexFromRoute = previousChapterIndex;
            }
        },
        updateQuery(query) {
            const currentQuery = { ...this.$route.query, ...query };
            this.$router.replace({ query: currentQuery });

        },
        getName(catename) {
            localStorage.setItem('categoryname', catename);
        },
        getDate(lessonNumber) {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + ' ' + time;
            sessionStorage.setItem('startquiz', dateTime);
            localStorage.setItem('lessonNumber', lessonNumber);
            localStorage.setItem('currentPath', this.$route.fullPath);
        },
        async fetchData() {
            try {

                const chapterId = this.$route.params.id;

                const response = await axios.get('https://backend.nadyalloughat.com/api/units/' + chapterId + '/chapters', {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',
                    }
                });

                this.chapters = response.data;
                setTimeout(() => {
                    // this.startIntro();
                    const hasSeenIntro = localStorage.getItem('hasSeenIntro2');
                    const userhasSeen = JSON.parse(localStorage.getItem('userData'));
                    const SeenHas = userhasSeen.has_seen_tutorial_unit_page;
                    // let isRouteChanged = false;

                    // this.$watch('$route', () => {
                    //     isRouteChanged = true;
                    // });

                    if (this.$route.path === '/road-map/'+chapterId && !hasSeenIntro && SeenHas !== 1) {
                        this.startIntro();
                        this.fetchUpdateProfile();
                        localStorage.setItem('hasSeenIntro2', 'true'); // Mark as seen
                    }
                }, 1000);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }

        },
        getCurrentChapter() {
            if (this.chapters.length > 0 && this.chapters[this.currentChapterIndex]) {
                return this.chapters[this.currentChapterIndex];
            }
            return null;
        },

        clickdisab(event) {
            if (this.youachiev.status == 'completed') {
                event.target.disabled = true
            }
        }
    },
    created() {
        const lastViewedChapterIndex = sessionStorage.getItem('currentChapterIndex');
        if (lastViewedChapterIndex) {
            // Set the current chapter index based on the retrieved value
            this.currentChapterIndex = parseInt(lastViewedChapterIndex);
        }
    },
}
</script>
<style>
body {
    height: 100vh;
}

#app {
    height: 100%;
}

@media screen and (max-width: 600px) {
    body {
        height: auto !important;
    }
}
</style>