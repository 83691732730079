<template>
    <div>
        <div class="container nofound-page" dir="rtl">
            <div class="row vh-100 align-items-center">
                <div class="col-md-12 text-center">
                    <h1>٤٠٤</h1>
                    <h5 class="mb-5">لم يتم العثور على الصفحة</h5>
                    <p>الصفحة التي تبحث عنها غير موجودة أو حدث خطأ ما.</p>
                    <p>ارجع أو توجه إلى <a href="mailto:nadyaloughat.net">nadyaloughat.net</a> لاختيار اتجاه جديد.</p>

                    <div class="mt-5"><img src="../assets/images/cat-notpage.svg" alt=""></div>
                </div>
            </div>
        </div>
    </div>

</template>